<template>
  <span :class="{ highlighted_destination: isActive }">
    {{ formatLegInfo }}
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { JourneyLeg } from "../../types/JourneyLeg";

export default defineComponent({
  name: "FlightSearchResultLegInfo",
  props: {
    leg: {
      type: Object as PropType<JourneyLeg>,
    },
    index: {
      type: Number,
      default: 0,
    },
    totalLength: {
      type: Number,
      default: 0,
    },
    currentLeg: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isActive() {
      return this.index === this.currentLeg;
    },
    formatLegInfo() {
      return `${this.leg?.origin.city} (${this.leg?.origin.iata_code}) - ${
        this.leg?.destination.city
      } (${this.leg?.destination.iata_code})${
        this.index === this.totalLength - 1 ? "" : ","
      }`;
    },
  },
});
</script>
