<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_20_7"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.0693054" y="0.604004" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_20_7)">
      <path
        d="M3.06931 21.604V19.604H21.0693V21.604H3.06931ZM4.91931 16.604L2.06931 11.754L3.61931 11.454L5.36931 13.004L10.1693 11.704L6.11931 4.85403L8.06931 4.25403L14.9193 10.404L19.9193 9.05403C20.4526 8.90403 20.936 9.00403 21.3693 9.35403C21.8026 9.70403 22.0193 10.1707 22.0193 10.754C22.0193 11.1207 21.9068 11.4457 21.6818 11.729C21.4568 12.0124 21.1693 12.204 20.8193 12.304L4.91931 16.604Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MFlightTakeOffIcon",
});
</script>
