import { RouteRecordRaw } from "vue-router";
import FlightSearch from "@/modules/Flight/views/FlightSearch.vue";
import FlightSearchResult from "@/modules/Flight/views/FlightSearchResult.vue";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FLIGHTS,
    name: "flightSearch",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
    component: FlightSearch,
  },
  {
    path: PATH.FLIGHTS_SEARCH_RESULT,
    name: "flightSearchResult",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
    component: FlightSearchResult,
  },
];

export default routes;
