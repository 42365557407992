import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgPriceRange = _resolveComponent("AgPriceRange")!
  const _component_AgFilterDropdown = _resolveComponent("AgFilterDropdown")!
  const _component_AgCheckbox = _resolveComponent("AgCheckbox")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgCard = _resolveComponent("AgCard")!

  return (_openBlock(), _createBlock(_component_AgCard, { class: "flightFilterWrap" }, {
    default: _withCtx(() => [
      _createVNode(_component_AgDiv, { class: "head" }, {
        default: _withCtx(() => [
          _createTextVNode(" Filter ")
        ]),
        _: 1
      }),
      _createVNode(_component_AgDiv, { class: "d-flex" }, {
        default: _withCtx(() => [
          _createVNode(_component_AgFilterDropdown, {
            "test-id": "",
            label: "Price Range (PKR)"
          }, {
            Items: _withCtx(() => [
              _createVNode(_component_AgPriceRange, {
                "onUpdate:rangeSliderValue": _ctx.handleUpdateRange,
                min: _ctx.localFilters.priceRange[0],
                max: _ctx.localFilters.priceRange[1],
                "thumb-size": 20,
                "thumb-label": "always",
                "test-id": ""
              }, null, 8, ["onUpdate:rangeSliderValue", "min", "max"])
            ]),
            _: 1
          }),
          _createVNode(_component_AgFilterDropdown, {
            "test-id": "",
            label: "Airlines",
            onClick: _cache[2] || (_cache[2] = (e) => e.stopPropagation())
          }, {
            Items: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.airlines, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgCheckbox, {
                  onClick: _cache[0] || (_cache[0] = (e) => e.stopPropagation()),
                  modelValue: _ctx.localFilters.airlines,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFilters.airlines) = $event)),
                  key: index,
                  value: item.value,
                  label: item.label,
                  "test-id": ""
                }, null, 8, ["modelValue", "value", "label"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_AgFilterDropdown, {
            "test-id": "",
            label: "Stops"
          }, {
            Items: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.stops, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgCheckbox, {
                  onClick: _cache[3] || (_cache[3] = (e) => e.stopPropagation()),
                  modelValue: _ctx.localFilters.stops,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localFilters.stops) = $event)),
                  key: index,
                  value: item.value,
                  label: item.label,
                  "test-id": ""
                }, null, 8, ["modelValue", "value", "label"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_AgFilterDropdown, {
            "test-id": "",
            label: "Departure Time"
          }, {
            Items: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters.departureTime, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgCheckbox, {
                  modelValue: _ctx.localFilters.departureTime,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localFilters.departureTime) = $event)),
                  onClick: _cache[6] || (_cache[6] = (e) => e.stopPropagation()),
                  key: index,
                  value: item,
                  label: item,
                  "test-id": ""
                }, null, 8, ["modelValue", "value", "label"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AGButton, {
        "test-id": "",
        onClick: _ctx.handleChangeSearchDialogOpen,
        variant: "link",
        class: "change_search_btn_modal"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Change Search")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}