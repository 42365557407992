<template>
  <ag-heading :title="localLabel" variant="label"></ag-heading>

  <AgAutoComplete
    v-model="searchValue"
    icon="calendar"
    :items="formattedAirports"
    item-label="label"
    @keyup="onChangeSearchField"
    :is-loading="$store.getters.isAirportFetching"
    @update:modelValue="onUpdateModelValue"
    class="flightField"
    :value="value"
    :error="error"
    :error-message="errorMessage"
  >
    <template #dropdownItem="{ itemProps, itemData }">
      <AgTextFieldDropDownItem v-bind="itemProps">
        <AgDiv class="flightDropdownWrap">
          <AgIcon class="flight_icon_wrap" test-id="" name="flightGreenIcon" />
          <AgDiv class="airport_name">
            {{ itemData.city }}, {{ itemData.country }}
            <AgDiv>{{ itemData.airport }}</AgDiv>
          </AgDiv>
          <AgDiv class="airport_code">
            {{ itemData.iata_code }}
          </AgDiv>
        </AgDiv>
      </AgTextFieldDropDownItem>
    </template>
  </AgAutoComplete>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Place } from "../../types/Place";

export default defineComponent({
  name: "AirportSearchAutoComplete",
  props: {
    label: {
      type: String,
      default: "From",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: Object as PropType<Place>,
      required: true,
    },
    items: {
      type: Array as () => Array<Place>,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
  },
  data() {
    return {
      searchValue: "",
      localLabel: this.label,
      isLoading: false,
      airports: [] as Array<Place>,
      selectedValue: null,
    };
  },
  computed: {
    formattedAirports() {
      const data = this.airports.map((item: Place) => {
        return {
          ...item,
          label: item?.label,
          value: item,
        };
      });

      return data;
    },
  },
  methods: {
    onChangeSearchField(e: any) {
      this.searchValue = e.target.value;
      this.$emit(`update:${this.name}`, e.target.value);
    },
    onUpdateModelValue(value: Place) {
      this.$emit(`onSelect:${this.name}`, value);
      this.selectedValue != value;
    },
  },

  watch: {
    "$store.getters.isAirportFetching": function () {
      this.isLoading = this.$store.getters.isAirportFetching;
    },
    items: {
      handler(places: Array<Place>) {
        this.airports = places;
      },
      deep: true,
    },
    value: {
      handler(newVal: Place) {
        const val = {
          ...newVal,
          label: newVal?.label,
          value: newVal,
        };
        this.selectedValue != val;
      },
    },
  },
});
</script>
