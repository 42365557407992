import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32f85fee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-item-p" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgTextFieldDropDownItem = _resolveComponent("AgTextFieldDropDownItem")!
  const _component_AgAutoComplete = _resolveComponent("AgAutoComplete")!

  return (_openBlock(), _createBlock(_component_AgAutoComplete, {
    modelValue: _ctx.searchValue,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      _ctx.onUpdateModelValue
    ],
    value: _ctx.selectedPassenger,
    type: "text",
    testId: 
      _ctx.genTestId(_ctx.ELEMENT_TYPES.TEXT_FIELD, 'passenger-autocomplete-searchValue')
    ,
    icon: "calendar",
    items: _ctx.filteredPassengers,
    onkeyup: _ctx.onChangeSearchField,
    label: "Passengers List",
    "is-loading": _ctx.isPassengerFetching,
    class: "agent-feild",
    clearable: false
  }, {
    dropdownItem: _withCtx(({ itemProps, itemData }) => [
      _createVNode(_component_AgTextFieldDropDownItem, _mergeProps(itemProps, {
        testId: 
          _ctx.genTestId(
            _ctx.ELEMENT_TYPES.SELECT_FIELD,
            `passenger-autocomplete-${itemData.value}`
          )
        
      }), {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(itemData.label), 1)
        ]),
        _: 2
      }, 1040, ["testId"])
    ]),
    _: 1
  }, 8, ["modelValue", "value", "testId", "items", "onkeyup", "onUpdate:modelValue", "is-loading"]))
}