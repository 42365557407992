<template>
  <ag-card test-id="">
    <ag-accordion
      class="flight_accordion_wrap margin_bottom_0"
      :panel-value="[1, 2]"
    >
      <ag-accordion-panel>
        <template #flightInfo>
          <AgRow test-id="">
            <AgColumn test-id="" sm="12" md="3" lg="2">
              <AgIconBox
                test-id=""
                :title="renderFlightCount"
                :sub-title="renderDepartureDate(flightLeg?.departure_datetime)"
                icon="departureIcon"
              ></AgIconBox>
            </AgColumn>
            <AgColumn test-id="" sm="12" md="9" lg="10">
              <AgFlightDetailCard
                test-id=""
                :airline-logo="flightLeg.segment[0].operating_airline?.logo"
                :airline-code="flightLeg?.segment[0].flight_number[0]"
                :duration="legDuration(flightLeg?.leg_duration)"
                :from-destination="flightLeg?.origin?.iata_code"
                :from-time="formatTime(flightLeg.departure_datetime)"
                :to-time="formatTime(flightLeg.arrival_datetime)"
                :to-destination="flightLeg?.destination?.iata_code"
                :airline-name="flightLeg.segment[0].operating_airline?.name"
                fare-info="true"
              />
            </AgColumn>
          </AgRow>
        </template>
        <template #tabSection>
          <AgHeading
            class="flight_info_green_box"
            :title="renderDepartureDate(flightLeg.departure_datetime)"
            variant="h4"
            test-id=""
          />
          <FlightRoute
            v-for="(segment, index) in flightLeg.segment"
            :next-departure-time="getNextDepartureTime(index)"
            :show-lay-off="index + 1 < flightLeg.segment.length"
            :key="index"
            :segment="segment"
          />
        </template>
      </ag-accordion-panel>
    </ag-accordion>
  </ag-card>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import FlightRoute from "@/ag-flight-components/components/FlightSearchResults/FlightRoute.vue";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import { FlightLeg } from "@/ag-flight-components/types/Flights";

export default defineComponent({
  name: "FlightResultDetailCard",
  components: { FlightRoute },
  props: {
    flightLeg: {
      type: Object as PropType<FlightLeg>,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatTime: (time: Date) => {
      return TimeUtility.parsedFlightTimeorDate(time);
    },
    legDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },

    getNextDepartureTime(index: number) {
      return index + 1 < this.flightLeg.segment.length
        ? this.flightLeg.segment[index + 1].departure_datetime
        : new Date();
    },
    renderDepartureDate: (departure_date: Date) => {
      return TimeUtility.parsedFlightTimeorDate(
        departure_date,
        "EEEE, MMMM dd yyyy"
      );
    },
  },
  computed: {
    renderFlightCount() {
      return `Flight ${this.index + 1}`;
    },
  },
});
</script>
