<template>
  <AgRow test-id="">
    <AgColumn
      test-id=""
      sm="6"
      lg="6"
      md="6"
      v-for="(booking, index) in selectedFlightLegs"
      :key="index"
    >
      <SelectedFlightLegItem :selected-leg="booking" :index="index" />
    </AgColumn>
  </AgRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SelectedFlightLeg } from "@/ag-flight-components/types/Flights";
import SelectedFlightLegItem from "./SelectedFlightLegItem.vue";

export default defineComponent({
  name: "SelectedFlightLegs",
  components: {
    SelectedFlightLegItem,
  },
  computed: {
    selectedFlightLegs(): Array<SelectedFlightLeg> {
      return this.$store.state.flightModule.selectedFlightLegs;
    },
  },
});
</script>
