<template>
  <ag-loader v-if="isLoading" />
  <template v-else>
    <ag-row>
      <ag-column xs="12" sm="12" md="8" lg="9">
        <AgCard test-id="">
          <AgRow test-id="" class="v-center">
            <AgColumn test-id="" md="9">
              <AgSelectedFlightInfo>
                <template #pnrInfo>
                  <AgDiv class="booking_id_wrap"
                    >Booking ID: <span>{{ renderBookingId }}</span></AgDiv
                  >
                </template>
              </AgSelectedFlightInfo>
            </AgColumn>
            <AgColumn test-id="" md="3" class="text-right">
              <AGButton
                test-id=""
                type="button"
                :variant="renderStatusSeverity"
              >
                {{ renderBookingStatus }}
              </AGButton>
            </AgColumn>
          </AgRow>
        </AgCard>
      </ag-column>
      <ag-column xs="12" sm="12" md="4" lg="3">
        <AgCard>
          <AgPnrDetail test-id="" :pnr="supplierPnr" :expiry="pnrExpiresAt" />
        </AgCard>
      </ag-column>
    </ag-row>
    <ag-row>
      <ag-column xs="12" sm="12" md="8" lg="9">
        <AgCard test-id="">
          <ag-heading
            variant="h2"
            title="Flight Details"
            class="margin_bottom_20"
          />
          <FlightDetailAccordion
            v-for="(flightLeg, index) in flightDetails?.flight_legs"
            :key="index"
            :flight-leg="flightLeg"
            :index="index"
          />
        </AgCard>
        <AgCard test-id="">
          <ag-heading
            variant="h2"
            title="Passenger Details"
            class="margin_bottom_20"
          />
          <PassengerDetailCard
            v-for="(passenger, index) in flightDetails?.passengers"
            :key="index"
            :passenger="passenger"
            :is-international="flightDetails?.is_international"
          />
        </AgCard>
        <AgCard test-id="" v-show="showAdditionalDetails">
          <ag-heading
            variant="h2"
            title="Additional Services"
            class="margin_bottom_20"
          />
          <AdditionalDetailsCard
            :has-meal="hasMeal"
            :luggage-info="luggageInfo"
          />
        </AgCard>
        <AgCard test-id="" v-show="showAdditionalDetailsOnReturn">
          <ag-heading variant="h2" title="Additional Services Return Flight" />
          <AdditionalDetailsCard
            :has-meal="isReturnMeal"
            :luggage-info="hasReturnluggageInfo"
          />
        </AgCard>
      </ag-column>
      <ag-column xs="12" sm="12" md="4" lg="3">
        <AgCard>
          <AgSidebarThumb test-id="" thumb="sidebarFlightThumb1">
            <PriceSummary>
              <template #flightDetailsActionButtons>
                <template v-if="isBookingCancelled">
                  <h3
                    style="
                      font-weight: bold;
                      color: #ed6c02;
                      text-align: center;
                    "
                  >
                    Booking {{ renderBookingStatus }}
                  </h3>
                </template>

                <template v-else>
                  <AGButton
                    :disabled="isFlightWaitListed"
                    v-show="!isFlightIssued"
                    @click="onProceedToIssuance"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Proceed To Issuance</AGButton
                  >
                  <AGButton
                    v-show="!isFlightCancelled"
                    :is-loading="isCancelBookingLoading"
                    @click="isCancelationDialogOpen = true"
                    class="full-width margin_bottom_10"
                    test-id=""
                    variant="danger"
                    >Cancel Booking</AGButton
                  >
                  <AGButton
                    v-show="isIssuedAndisPIAAndisNotInternational"
                    :is-loading="isVoidFlightTicketLoading"
                    @click="onVoidPIATicket"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Void ticket</AGButton
                  >
                  <AGButton
                    :is-loading="isDownloadTicketLoading"
                    @click="onDownloadTicket"
                    class="full-width margin_bottom_10"
                    test-id=""
                    >Download ticket</AGButton
                  >
                  <AGButton
                    v-if="showDownloadAirlineTicketButton()"
                    :is-loading="isDownloadTicketLoading"
                    @click="onClickAirlineTicket()"
                    class="full-width margin_bottom_10"
                    test-id="emailTicket"
                    >Email ticket
                  </AGButton>
                </template>
                <AirlineTicketModal
                  :show-modal="showAirlineTicketModal"
                  @onSendAirlineTicket="onSendAirlineTicket"
                  @closeModal="showAirlineTicketModal = false"
                />
              </template>
            </PriceSummary>
          </AgSidebarThumb>
        </AgCard>
      </ag-column>
    </ag-row>
  </template>
  <CancelBookingConfirmationDialog
    :is-open="isCancelationDialogOpen"
    :handle-accpet="onCancelBooking"
    :handle-reject="handleCloseCancelationDialog"
  />
  <PriceUpdatedDialog
    :is-open="isPriceUpdatedDialog"
    :handle-accpet="handlePriceUpdateContinue"
    :gross-fare="flightDetails?.equivalent_ag_total_amount || '-'"
    :currency="Currency"
  />
  <WaitListDialog :is-open="isWaitListDialog" />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AirlineTicketModal from "../views/AirlineTicketModal.vue";
import { ALLOWED_SUPPLIERS } from "@/ag-portal-common/constants/allowedSuppliers";
import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";
import _ from "lodash";

import FlightDetailAccordion from "@/ag-flight-components/components/FlightBooking/FlightDetailAccordion.vue";
import PassengerDetailCard from "@/ag-flight-components/components/FlightBooking/PassengerDetailCard.vue";
import CancelBookingConfirmationDialog from "@/ag-flight-components/components/FlightBooking/CancelBookingConfirmationDialog.vue";
import AdditionalDetailsCard from "@/ag-flight-components/components/FlightBooking/AdditionalDetailsCard.vue";
import PriceUpdatedDialog from "@/ag-flight-components/components/FlightBooking/PriceUpdatedDialog.vue";
import WaitListDialog from "@/ag-flight-components/components/FlightBooking/WaitlistDialog.vue";
import { TimeUtility } from "@/ag-flight-components/utils/TimeUtility";
import PriceSummary from "@/ag-flight-components/components/FlightBooking/PriceSummary.vue";
import { BOOKING_STATUSES } from "@/ag-flight-components/enums/booking_statuses";
import { renderBaggageInfo } from "@/ag-flight-components/utils";
import { FlightLeg } from "@/ag-flight-components/types";
import { PATH } from "@/ag-portal-common/constants/path";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";
import analyticsService from "@/services/analytics.service"; // Root folder item
import { FLIGHT_ANALYTICS_EVENTS } from "@/constants/analyticsEvents"; // Root folder item
import { FORMAT_DD_MM_YYYY_HH_MM_24_HOURS } from "@/ag-portal-common/constants/dateTimeFormats";
import { format } from "date-fns";
import { handleConvertUTCtoZonedTime } from "@/ag-flight-components/utils";

export default defineComponent({
  name: "FlightBookingForm",
  components: {
    FlightDetailAccordion,
    PassengerDetailCard,
    PriceSummary,
    CancelBookingConfirmationDialog,
    AdditionalDetailsCard,
    PriceUpdatedDialog,
    AirlineTicketModal,
    WaitListDialog,
  },
  data() {
    return {
      isCancelationDialogOpen: false,
      isPriceUpdatedDialog: false,
      isWaitListDialog: false,
      showAirlineTicketModal: false,
    };
  },
  methods: {
    onSendAirlineTicket(emails: string[]) {
      this.$store.dispatch("sendAirlineTicket", {
        payload: {
          pnr: this.supplierPnr,
          airline_provider: this.flightDetails?.flight_sub_provider,
          emails,
        } as SendAirlineTicketBodyRequest,
        callback: () => (this.showAirlineTicketModal = false),
      });
    },
    showDownloadAirlineTicketButton() {
      const status = this.renderBookingStatus;
      const supplier = this.flightDetails?.flight_sub_provider;
      const isAllowedSupplier = ALLOWED_SUPPLIERS.includes(supplier);
      return (
        status === BOOKING_STATUS.ISSUED.toUpperCase() && isAllowedSupplier
      );
    },
    onClickAirlineTicket() {
      // this.activeBooking = booking;
      this.showAirlineTicketModal = true;
    },
    onProceedToIssuance() {
      const flight_sub_provider: string =
        this.flightDetails?.flight_sub_provider || "";
      const sub_providers_enum: IObject = {
        FLYJINNAH: "fly_jinnah",
        AIRARABIA: "air_arabia",
      };
      const supplier = Object.keys(sub_providers_enum).includes(
        this.flightDetails?.flight_sub_provider
      )
        ? sub_providers_enum[flight_sub_provider]
        : flight_sub_provider;

      const airline =
        this.flightDetails?.flight_legs[0].segment[0].marketing_airline.code;

      this.$router.push({
        path: PATH.FLIGHT_ISSUANCE,
        query: {
          pnr: this.supplierPnr,
          supplier,
          ...(supplier === "SABRE" && { sabre_airline: airline }),
        },
      });
    },
    onSuccessHandler() {
      const booking_id = this.$route.params.id;
      this.$store.dispatch("getFlightBooking", { booking_id });
    },
    onCancelBooking() {
      const booking_id = this.$route.params.id;
      this.isCancelationDialogOpen = false;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("cancelFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
    handleCloseCancelationDialog() {
      this.isCancelationDialogOpen = false;
    },
    onDownloadTicket() {
      const booking_id = this.$route.params.id;
      const payload = {
        booking_id,
      };
      analyticsService.logActionEvent(
        FLIGHT_ANALYTICS_EVENTS.AG_FLIGHT_TICKET_DOWNLOAD,
        payload
      );
      this.$store.dispatch("downloadFlightBooking", payload);
    },
    handlePriceUpdateContinue() {
      this.isPriceUpdatedDialog = false;
    },
    onVoidPIATicket() {
      const booking_id = this.$route.params.id;
      const payload = {
        booking_id,
      };
      this.$store.dispatch("voidPIAFlightBooking", {
        payload,
        successCallback: this.onSuccessHandler,
      });
    },
  },
  computed: {
    Currency() {
      return localStorage.getItem("currency") || "PKR";
    },
    isFlightWaitListed() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (_.capitalize(bookingStatus) === BOOKING_STATUSES.WAITLIST) {
        return true;
      } else {
        return false;
      }
    },
    renderBookingId() {
      return this.$route.params.id;
    },
    renderBookingStatus() {
      return this.$store.getters.flightBookingStatus;
    },
    flightDetails() {
      return this.$store.getters.flightDetails;
    },
    supplierPnr() {
      return this.$store.state?.flightBookingModule?.supplierPnr;
    },
    pnrExpiresAt() {
      const pnrExpiryTime =
        this.$store.state?.flightBookingModule?.pnrExpiryDate;
      if (pnrExpiryTime) {
        const utcZonedPnrExpiryTime = handleConvertUTCtoZonedTime(
          pnrExpiryTime
        ) as string;
        return utcZonedPnrExpiryTime
          ? format(
              new Date(utcZonedPnrExpiryTime),
              FORMAT_DD_MM_YYYY_HH_MM_24_HOURS
            )
          : null;
      }
      return "";
    },
    isLoading() {
      return this.$store.getters.isFlightBookingLoading;
    },
    isCancelBookingLoading() {
      return this.$store.getters.isFlightCancelBookingLoading;
    },
    isDownloadTicketLoading() {
      return this.$store.getters.isDownloadTicketLoading;
    },
    isBookingCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      return (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      );
    },
    luggageInfo(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info = flight_legs.find((x: FlightLeg) => x.baggage_info) || {};
      if (!_.isEmpty(leg_info?.baggage_info)) {
        return renderBaggageInfo(leg_info?.baggage_info);
      }
      return "";
    },
    hasReturnluggageInfo(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs =
        this.flightDetails?.flight_legs[1]?.baggage_info || "";
      if (flight_legs) {
        return renderBaggageInfo(flight_legs);
      }
      return "";
    },
    hasMeal(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const flight_legs = this.flightDetails?.flight_legs || [];
      const leg_info: FlightLeg =
        flight_legs.find((x: FlightLeg) => x.baggage_info) || {};
      return leg_info.has_meal;
    },
    isReturnMeal(): boolean {
      return this.flightDetails?.flight_legs[1]?.has_meal || false;
    },
    renderStatusSeverity() {
      switch (_.capitalize(this.renderBookingStatus)) {
        case BOOKING_STATUSES.CANCELED:
          return "danger";
        case BOOKING_STATUSES.CONFIRMED:
          return "primary";
        case BOOKING_STATUSES.ISSUED:
          return "primary";
        case BOOKING_STATUSES.WAITLIST:
          return "danger";
        default:
          return "primary";
      }
    },
    showAdditionalDetails() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.hasMeal || !!this.luggageInfo;
    },
    showAdditionalDetailsOnReturn() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.isReturnMeal || !!this.hasReturnluggageInfo;
    },
    isVoidFlightTicketLoading() {
      return this.$store.getters.isVoidFlightTicketLoading;
    },
    isIssuedAndisPIAAndisNotInternational() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      const isInternational =
        this.$store.getters.flightDetails.is_international;
      const isPIA =
        this.$store.getters.flightDetails?.flight_legs[0].segment[0]
          .operating_airline.name;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED &&
        isInternational === false &&
        isPIA === "Pakistan International Airlines"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightIssued() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.ISSUED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFlightCancelled() {
      const bookingStatus = this.$store.getters.flightBookingStatus;
      if (
        _.capitalize(bookingStatus) === BOOKING_STATUSES.CANCELED ||
        _.capitalize(bookingStatus) === BOOKING_STATUSES.VOIDED
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    const booking_id = this.$route.params.id;
    this.$store.dispatch("getFlightBooking", { booking_id });
  },

  watch: {
    "$store.getters.flightDetails": {
      handler(flightDetails) {
        const bookingStatus = this.$store.getters.flightBookingStatus as string;
        const isBookingWaitlisted =
          bookingStatus === BOOKING_STATUSES.WAITLIST.toLocaleUpperCase();

        if (isBookingWaitlisted) {
          console.log("Khuljasimsim");
          this.isWaitListDialog = true;
        }
        const isBookingConfirmed =
          bookingStatus === BOOKING_STATUSES.CONFIRMED.toLocaleUpperCase();

        if (isBookingConfirmed && flightDetails?.price?.fare_updated) {
          this.isPriceUpdatedDialog = true;
        }
      },
    },
  },
});
export interface SendAirlineTicketBodyRequest {
  pnr: string;
  airline_provider: string;
  emails: string[];
}
</script>
