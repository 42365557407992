export enum TravelerTypes {
  ADULT = "ADT",
  CHILD = "CNN",
  INFANT = "INF",
}

export function getTravelerTypeName(type: string) {
  switch (type) {
    case TravelerTypes.ADULT:
      return "Adult";
    case TravelerTypes.CHILD:
      return "Child";
    case TravelerTypes.INFANT:
      return "Infant";
  }
}
