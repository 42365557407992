import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { FLIGHT_ISSUANCE_API_PATH } from "@/ag-flight-components/constants/apiEndPoints";
import {
  FETCH_FLIGHT_PNR_PAYLOAD,
  ISSUE_TICKET_PAYLOAD,
} from "@/ag-flight-components/types";

class FlightIssuanceService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public fetchPNR(params: FETCH_FLIGHT_PNR_PAYLOAD): Promise<IAGResponse<any>> {
    return this.restClient.post(FLIGHT_ISSUANCE_API_PATH.PNR_DETAILS, params);
  }
  public issueTicket(params: ISSUE_TICKET_PAYLOAD): Promise<IAGResponse<any>> {
    return this.restClient.post(FLIGHT_ISSUANCE_API_PATH.ISSUE_TICKET, params);
  }
}

export default FlightIssuanceService;
