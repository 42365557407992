import * as yup from "yup";

export const normalFlightSearchValidation = yup.object().shape({
  origin: yup.object().nullable().required("Please select origin"),
  destination: yup.object().nullable().required("Please select destination"),
});

export const multicityFlightSearchValidation = yup.array().of(
  yup.object().shape({
    origin: yup.object().nullable().required("Please select origin"),
    destination: yup.object().nullable().required("Please select destination"),
    departure_date: yup.date().required("Please select departure date"),
  })
);
