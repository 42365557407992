import RestClientService from "@/ag-portal-common/services/restClient.service";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { AIRPORT_API_PATH } from "../constants/apiEndPoints";

class AirportService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public get(params: string): Promise<IAGResponse<any>> {
    return this.restClient.get(AIRPORT_API_PATH.GET + `?query=${params}`);
  }
}

export default AirportService;
