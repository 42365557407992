<template>
  <ag-modal :is-open="isOpen" @close="closeModal" modal-width="40%">
    <template #header>
      <ag-heading variant="h4" title="Airline Tickets" />
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-close')"
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="closeModal"
        >Close
      </a-g-button>
    </template>

    <template #body>
      <ag-heading
        title="Please type email in the box and press enter. Airline tickets will be sent on emails below."
        variant="p"
      />

      <a-g-text-field
        label="Email"
        :testId="
          genTestId(ELEMENT_TYPES.TEXT_FIELD, 'airline-ticket-modal-email')
        "
        :value="emailField"
        @focusout="emailFieldErrorMessage = ''"
        :error="emailFieldErrorMessage"
        v-model="emailField"
        @keydown.enter="updateEmails"
      />

      <div class="d-flex margin_bottom_10 margin_top_20">
        <ag-chip
          class="margin_right_10"
          color="success"
          v-bind:key="index"
          :testId="
            genTestId(ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-email-chip')
          "
          closable
          @click:close="onRemoveEmail(index)"
          v-for="(email, index) in emails"
        >
          {{ email }}
        </ag-chip>
      </div>
    </template>
    <template #footer>
      <a-g-button
        :testId="genTestId(ELEMENT_TYPES.BUTTON, 'airline-ticket-modal-send')"
        @click="$emit('onSendAirlineTicket', emails)"
        :is-loading="$store.getters.isSendingAirlineTicket"
      >
        Send Airline Tickets
      </a-g-button>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { genTestId, validateEmail } from "@/ag-portal-common/utils/helpers";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { IObject } from "@/ag-portal-common/interfaces/object.interface";

export default defineComponent({
  name: "AirlineTicketModal",
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data(): {
    ELEMENT_TYPES: IObject;
    emailField: string;
    isOpen: boolean;
    emails: string[];
    emailFieldErrorMessage: string;
  } {
    return {
      emailFieldErrorMessage: "",
      ELEMENT_TYPES,
      emailField: "",
      isOpen: this.showModal,
      emails: [],
    };
  },
  beforeMount() {
    let user = this.user();
    this.emails.push(user?.email);
  },
  methods: {
    genTestId,
    updateEmails() {
      this.emailFieldErrorMessage = "";
      if (validateEmail(this.emailField)) {
        if (this.emails.length >= 5) {
          this.emailFieldErrorMessage = "Only 5 emails at a time are allowed";
          return;
        }
        this.emails.push(this.emailField);
        this.emailField = "";
      } else {
        this.emailFieldErrorMessage = "Invalid email";
      }
    },
    onRemoveEmail(index: number) {
      this.emails.splice(index, 0);
    },
    closeModal() {
      this.isOpen = false;
      this.$emit("closeModal");
    },
  },
  watch: {
    showModal(value: boolean) {
      this.isOpen = value;
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2;
    return { user };
  },
});
</script>
