<template>
  <AgDiv
    class="d-flex justify-content-end flight_dropdown_parent_flight flex-wrap margin_bottom_20"
    test-id=""
  >
    <AGButtonDropDown class="flight_dropdown" :items="routeTypeOptions">
      <template #label>{{ renderRouteTypeLabel }}</template>
      <AGDropDownButtonItem
        v-for="(item, index) in routeTypeOptions"
        :key="index"
        :name="item.label"
        @click="changeRouteType(item)"
      />
    </AGButtonDropDown>
    <AGButtonDropDown class="flight_dropdown" :items="cabinClassOptions">
      <template #label>{{ renderCabinClassLabel() }}</template>
      <AGDropDownButtonItem
        v-for="(item, index) in cabinClassOptions"
        :key="index"
        :name="item.label"
        @click="changeCabinClass(item)"
      />
    </AGButtonDropDown>
    <AgTravelerCountDropdown test-id="" :label="travelerCountLabel">
      <template #Items>
        <AgTravelerCountItem
          icon="adultIcon"
          name="AdultCount"
          @update:AdultCount="updateAdultCount"
          :count="$store.state.flightModule.adult_count"
          label="Adult (12+)"
          test-id=""
        ></AgTravelerCountItem>
        <AgTravelerCountItem
          icon="childrenIcon"
          label="Children"
          name="ChildCount"
          @update:ChildCount="updateChildCount"
          :count="$store.state.flightModule.child_count"
          hint="(2 to less then 12)"
          test-id=""
        ></AgTravelerCountItem>
        <AgTravelerCountItem
          icon="infantIcon"
          label="Infants"
          name="InfantCount"
          :count="$store.state.flightModule.infant_count"
          @update:InfantCount="updateInfantCount"
          :disabled="handleDisableInfantPlusBtn"
          hint="(0 to less then 2)"
          test-id=""
        ></AgTravelerCountItem>
      </template>
    </AgTravelerCountDropdown>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { RouteTypeOptions, CabinClassOptions } from "../../constants";
import { OptionValue } from "../../types/OptionValue";

export default defineComponent({
  name: "FlightSearchOptions",
  data() {
    return {
      cabin_class: "Economy",
      route_type: "One way",
      travelerCountLabel: "1 Adult",
      cabinClassOptions: CabinClassOptions,
      routeTypeOptions: RouteTypeOptions,
    };
  },
  computed: {
    renderRouteTypeLabel(): string {
      const routeType = RouteTypeOptions.find(
        (x: any) => x.value === this.$store.state.flightModule.route_type
      );
      return routeType?.label as string;
    },
    handleDisableInfantPlusBtn(): boolean {
      return (
        this.$store.state.flightModule.infant_count >=
        this.$store.state.flightModule.adult_count
      );
    },
  },
  methods: {
    renderCabinClassLabel(): string {
      const cabinClass = CabinClassOptions?.find(
        (x: any) => x.value === this.$store.state.flightModule.cabin_class
      );
      return cabinClass?.label as string;
    },
    changeCabinClass(item: OptionValue): void {
      this.$store.commit("saveCabinClass", item.value);
    },
    changeRouteType(item: OptionValue): void {
      this.$store.commit("saveRouteType", item.value);
    },
    updateAdultCount(value: number): void {
      this.$store.commit("saveAdultCount", value);
    },
    updateChildCount(value: number): void {
      this.$store.commit("saveChildCount", value);
    },
    updateInfantCount(value: number): void {
      this.$store.commit("saveInfantCount", value);
    },
    updateTravelerDropdownLabel() {
      const { adult_count, child_count, infant_count } =
        this.$store.state.flightModule;
      let label = "";

      if (adult_count > 0) {
        label += `${adult_count} Adult`;
        if (adult_count > 1) {
          label += "s";
        }
      }

      if (child_count > 0) {
        if (label !== "") {
          label += ", ";
        }
        label += `${child_count} Child`;
        if (child_count > 1) {
          label += "ren";
        }
      }

      if (infant_count > 0) {
        if (label !== "") {
          label += ", ";
        }
        label += `${infant_count} Infant`;
        if (infant_count > 1) {
          label += "s";
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      this.travelerCountLabel = label;
    },
  },
  watch: {
    "$store.state.flightModule.adult_count": {
      handler() {
        this.updateTravelerDropdownLabel();
      },
      immediate: true,
    },
    "$store.state.flightModule.child_count": {
      handler() {
        this.updateTravelerDropdownLabel();
      },
      immediate: true,
    },
    "$store.state.flightModule.infant_count": {
      handler() {
        this.updateTravelerDropdownLabel();
      },
      immediate: true,
    },
  },
});
</script>
