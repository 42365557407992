<template>
  <div class="m-combobox" :disabled="disabled" :has-error="hasError">
    <label :for="inputId" class="m-label" :class="{ 'm-active': isActive }">
      {{ label }}
    </label>
    <div class="m-combobox-container">
      <input
        :id="inputId"
        v-model.trim="inputModal"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        class="m-input"
        :placeholder="placeholder"
        type="text"
        :readonly="disabled"
      />
      <div class="m-icon" :class="{ 'm-active': isFocused }">
        <m-dropdown-icon />
      </div>
      <template v-if="isFocused">
        <template v-if="filteredOptions.length">
          <div class="m-dropdown">
            <div
              class="m-item"
              v-for="(option, index) in filteredOptions"
              :key="index"
              @click="handleSelect(option)"
            >
              {{ option[itemLabel] }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="m-dropdown">
            <div class="m-item">Nothing Found</div>
          </div>
        </template>
      </template>
    </div>
    <div v-show="hasError" class="m-input-error-text">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MDropdownIcon } from "./icons";

type ComboboxOptions = PropType<
  {
    [index: string]: string;
  }[]
>;

export default defineComponent({
  name: "MCombobox",
  components: { MDropdownIcon },
  data() {
    return {
      canFilter: true,
      inputModal: this.inputValue,
      isActive: false,
      isFocused: false,
      inputId: `m-combobox-${Math.random().toString(36).substring(7)}`,
    };
  },
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: true,
    },
    itemLabel: {
      type: String,
      required: true,
    },
    options: {
      type: Array as ComboboxOptions,
      default: () => [],
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:inputValue"],
  computed: {
    filteredOptions() {
      let options = this.options;

      if (this.canFilter) {
        options = options.filter((option) =>
          (option[this.itemLabel] as string)
            .toLowerCase()
            .includes(this.inputModal.toLowerCase())
        );
      }

      return options.slice(0, 20);
    },
  },
  watch: {
    inputModal(val: string) {
      if (val === "") {
        this.canFilter = true;
      }
    },
    inputValue(val: string) {
      if (val === "") {
        this.inputModal = "";

        if (!this.isFocused) {
          this.handleBlur();
        }
      } else {
        const selectedOption = this.options.find(
          (option) => option[this.itemLabel] === val
        );

        if (selectedOption) {
          this.handleSelect(selectedOption);
        }
      }
    },
  },
  methods: {
    handleSelect(option: { [index: string]: string }): void {
      this.inputModal = option[this.itemLabel];
      this.$emit("update:inputValue", option[this.itemValue]);

      this.handleBlur();
      this.canFilter = false;
    },
    handleInput(): void {
      if (this.inputModal === "") {
        this.$emit("update:inputValue", "");
      }
    },
    handleFocus() {
      this.isActive = true;
      this.isFocused = true;
    },
    handleBlur() {
      setTimeout(() => {
        this.isActive = this.inputModal !== "" || this.placeholder !== "";
        this.isFocused = false;
      }, 200);
    },
  },
  mounted() {
    this.handleBlur();
  },
});
</script>

<style scoped>
:root {
  --label-color: #333;
  --text-color: #555;
  --border-color: #ccc;
  --error-color: #ff0000;
}
.m-combobox {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  height: fit-content;
}

.m-combobox[disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.m-combobox[has-error="true"] {
  --label-color: var(--error-color);
  --text-color: var(--error-color);
  --border-color: var(--error-color);
}

.m-label {
  position: absolute;
  top: 17px;
  left: 13px;
  padding: 0px 4px;

  pointer-events: none;
  transition: 0.2s ease-out;

  color: var(--label-color);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  background: #fff;
  z-index: 1;
}

.m-combobox-container {
  position: relative;
}

.m-icon {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;
  top: 0;

  transition: transform 0.25s ease-in-out;
}
.m-icon.m-active {
  transform: rotate(180deg);
}

.m-dropdown {
  width: 100%;

  border-radius: 4px;
  border: 1px solid #ccc;
  max-height: 170px;
  overflow-y: auto;
  background-color: #fff;

  position: absolute;
  top: 62px;

  z-index: 100;
}

.m-dropdown::-webkit-scrollbar {
  width: 8px;
}
.m-dropdown::-webkit-scrollbar-thumb {
  background-color: var(--text-color);
  border-radius: 4px;
}
.m-dropdown::-webkit-scrollbar-track {
  background-color: #dadada;
}

.m-dropdown .m-item {
  height: 48px;
  padding: 12px 16px;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-dropdown .m-item:hover {
  background-color: #f0f0f0;
}

.m-dropdown .m-item:hover:first-child {
  border-radius: 4px 4px 0 0;
}

.m-dropdown .m-item:hover:last-child {
  border-radius: 0 0 4px 4px;
}

.m-dropdown .m-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.m-input {
  width: 100%;
  padding: 16px;
  padding-right: 60px;
  border-radius: 4px;

  color: var(--text-color);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  transition: border-color 0.2s ease-out;

  border-radius: 4px;
  border: 1px solid var(--border-color);

  background-color: #fff;
}

.m-input:focus {
  outline: none;
  border-color: var(--text-color);
}

.m-label.m-active {
  top: -7px;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.m-input-error-text {
  position: absolute;
  bottom: -21px;
  left: 16px;
  color: var(--error-color);

  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
