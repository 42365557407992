import { firebaseAnalytics } from "@/ag-portal-common/firebase";
import mixpanel from "mixpanel-browser";
import * as firebase from "firebase/analytics";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";

type EVENT_DATA = {
  [key: string]: string | number;
};

class AnalyticsService {
  private shouldLogEvents: boolean;
  private analyticPrefix: string;

  constructor(prefix: string) {
    this.analyticPrefix = prefix;
    const mixpanel_key = process.env.VUE_APP_MIXPANEL_KEY as undefined | string;
    const envAppEventLoggingEnabled = process.env
      .VUE_APP_EVENT_LOGGING_ENABLED as undefined | string;
    this.shouldLogEvents = envAppEventLoggingEnabled == "true";
    if (mixpanel_key) {
      mixpanel.init(mixpanel_key);
    }
  }

  setUser(user: IUserV2) {
    firebase.setUserId(firebaseAnalytics, user.email);
    mixpanel.identify(user.email);
    mixpanel.people.set({
      email: user.email,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      fullName: user.full_name,
      phoneNumber: user.phone_number,
      roleUniqueId: user.role_unique_id,
      roleSubType: user.role_sub_type,
    });
  }

  private logEvent(eventName: string, data: EVENT_DATA = {}) {
    data = {
      ...data,
    };
    eventName = this.analyticPrefix + eventName;
    if (this.shouldLogEvents) {
      firebase.logEvent(firebaseAnalytics, eventName, data);
      mixpanel.track(eventName, data);
    }
  }

  logPageViewEvent(screen_name: string, page_name: string, data?: EVENT_DATA) {
    this.logEvent(page_name, {
      ...(data || {}),
      screen_name,
    });
  }

  logActionEvent = (event_name: string, data?: any) => {
    this.logEvent(event_name, data);
  };
}

export default AnalyticsService;
