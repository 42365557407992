<template>
  <FlightSearchOptions />
  <MulticityFlightSearch
    :errors="multicityError"
    :onFlightSearch="onFlightSearch"
    v-if="isMulticitySearch"
  />
  <NormalFlightSearch
    :errors="normalError"
    :onFlightSearch="onFlightSearch"
    v-else
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ValidationError } from "yup";
import { format } from "date-fns";

import { ROUTE_TYPE } from "../../enums/route_type";
import { formatMultiCityQueryParam } from "../../utils";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  normalFlightSearchValidation,
  multicityFlightSearchValidation,
} from "../../validations/flightSearchValidation";
import { FORMAT_YYY_MM_DD } from "@/ag-portal-common/constants/dateTimeFormats";
import { yupValidationErrorAsSchema } from "@/ag-portal-common/utils/helpers";
import { formatQueryPath } from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/services/analytics.service"; // Root folder item
import { FLIGHTS_ANALYTICS_COMMON_EVENTS } from "@/constants/analyticsEvents"; // Root folder item
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import getLocalbaseInstance from "../../constants/localbase.js";
import NormalFlightSearch from "./NormalSearch.vue";
import MulticityFlightSearch from "./MulticitySearch.vue";
import FlightSearchOptions from "./FlightSearchOptions.vue";

export default defineComponent({
  name: "FlightSearchComponent",
  data() {
    return {
      multicityError: {},
      normalError: {},
    };
  },
  computed: {
    isMulticitySearch(): boolean {
      return (
        this.$store.state.flightModule.route_type === ROUTE_TYPE.MULTI_CITY
      );
    },
  },
  components: {
    NormalFlightSearch,
    MulticityFlightSearch,
    FlightSearchOptions,
  },
  methods: {
    handleReturnDate(returnDate: string): any {
      const date = new Date(returnDate);
      return returnDate ? format(date, FORMAT_YYY_MM_DD) : null;
    },
    formatTrips(payload: any): string {
      if (this.isMulticitySearch) {
        const multiCityLegs = this.$store.state.flightModule.multiCityLegs;
        return formatMultiCityQueryParam(multiCityLegs).replace(/,$/, "");
      } else {
        return `${payload.origin},${payload.destination},${
          payload.departure_date
        }${
          this.handleReturnDate(payload.return_date)
            ? "," + this.handleReturnDate(payload.return_date)
            : ""
        }`.trim();
      }
    },

    async searchFlight(payload: any) {
      const db = getLocalbaseInstance();
      try {
        const multiCityLegs = this.$store.state.flightModule.multiCityLegs;
        if (payload?.route_type === ROUTE_TYPE.MULTI_CITY) {
          await multicityFlightSearchValidation.validate(multiCityLegs, {
            abortEarly: false,
          });
        } else {
          await normalFlightSearchValidation.validate(
            {
              origin: this.$store.state.flightModule?.origin,
              destination: this.$store.state.flightModule?.destination,
            },
            { abortEarly: false }
          );
        }

        const trips = this.formatTrips(payload);
        const query = {
          adult: payload.traveler_count.adult_count,
          child: payload.traveler_count.child_count,
          infant: payload.traveler_count.infant_count,
          route_type: payload.route_type,
          cabin_class: payload.cabin_class,
          trips,
        };
        const path = `${PATH.FLIGHTS_SEARCH_RESULT}${formatQueryPath(query)}`;
        this.$router.push(path);
        const queryPayload = {
          path,
          ...payload,
          ...(payload?.route_type === ROUTE_TYPE.MULTI_CITY && {
            origin: multiCityLegs[0]?.origin?.iata_code,
            departure_date: format(
              multiCityLegs[0]?.departure_date,
              FORMAT_YYY_MM_DD
            ),
            destination:
              multiCityLegs[multiCityLegs?.length - 1]?.destination?.iata_code,
          }),
        };
        db.collection("flights").add({
          ...queryPayload,
          timestamp: new Date().getTime(),
          path,
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          const err = yupValidationErrorAsSchema(ex);
          if (this.isMulticitySearch) {
            this.multicityError = err;
          } else {
            this.normalError = err;
          }
        }
      }
    },
    onFlightSearch() {
      const {
        origin,
        destination,
        departure_date,
        route_type,
        adult_count,
        child_count,
        infant_count,
        cabin_class,
        return_date,
      } = this.$store.state.flightModule;

      const isReturnType = route_type === ROUTE_TYPE.RETURN;

      const payload = {
        origin: origin?.iata_code,
        destination: destination?.iata_code,
        departure_date: format(departure_date, FORMAT_YYY_MM_DD),
        return_date: isReturnType ? this.handleReturnDate(return_date) : null,
        route_type: route_type,
        traveler_count: {
          adult_count: adult_count,
          child_count: child_count,
          infant_count: infant_count,
        },
        cabin_class: cabin_class,
        non_stop_flight: false,
      };

      this.searchFlight(payload);

      const analyticsPayload = {
        from: origin?.iata_code,
        to: destination?.iata_code,
        "departure-date": format(departure_date, FORMAT_YYY_MM_DD),
        "return-date": isReturnType ? this.handleReturnDate(return_date) : null,
        "route-type": route_type,
        "adult-traveler-count": adult_count,
        "child-traveler-count": child_count,
        "infant-traveler-count": infant_count,
        "cabin-class": cabin_class,
      };

      analyticsService.logActionEvent(
        FLIGHTS_ANALYTICS_COMMON_EVENTS.BOOKING_SEARCH,
        analyticsPayload
      );

      this.$emit("close_flight_search_dialog");
    },

    handleQuickChipClick(item: any) {
      this.$router.push(`${item?.fullPath}&from_chip=true`);
    },
    handleChipRouteType(type: ROUTE_TYPE) {
      if (type === ROUTE_TYPE.ONEWAY) {
        return "oneWayFlight";
      } else {
        return "returnFlight";
      }
    },
  },
});
</script>
