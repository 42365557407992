import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import FlightIssuance from "@/modules/FlightIssuance/views/FlightIssuance.vue";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FLIGHT_ISSUANCE,
    name: "flightIssuance",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.PERMISSIONS,
    },
    component: FlightIssuance,
  },
];

export default routes;
