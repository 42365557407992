import { IAgentFinances } from "@/ag-portal-common/interfaces/agentFinances.interface";
import { GetFinancesResponseModel } from "@/ag-portal-common/widgets/AgentFinancesWidget/models";

export class AgentFinancesMapper {
  private agentFinances: IAgentFinances = {
    temporaryCreditLimit: 0,
    totalNotInvoicedTicket: 0,
    currentBalance: 0,
    currentLimit: 0,
    availableCredit: 0,
    permanentCreditLimit: 0,
    accountStatus: "",
    currency: "",
    statusRemarks: "",
  };

  public mapAgentFinancesResponse(
    data: GetFinancesResponseModel
  ): IAgentFinances {
    this.agentFinances.availableCredit = Number(
      data?.financial_data?.available_credit
    );
    this.agentFinances.currentLimit = Number(
      data?.financial_data?.total_balance_current
    );
    this.agentFinances.currentBalance = Number(
      data?.financial_data?.current_balance
    );
    this.agentFinances.permanentCreditLimit = Number(
      data?.financial_data?.credit_limit
    );
    this.agentFinances.totalNotInvoicedTicket = Number(
      data?.financial_data?.total_of_not_invoiced_tickets
    );
    this.agentFinances.temporaryCreditLimit = Number(
      data?.financial_data?.temporary_credit_limit
    );
    this.agentFinances.currency = data?.financial_data?.currency_code;
    this.agentFinances.accountStatus = data?.financial_data?.customer_status;
    this.agentFinances.statusRemarks = data?.status_remarks;

    return this.agentFinances;
  }
}
