<template>
  <ag-modal :is-open="isOpen" modal-width="40%">
    <template #body>
      <AgDiv style="text-align: center; font-size: 21px"
        >Your booking is on <b style="color: red">WAITLIST</b></AgDiv
      >
      <p
        style="
          color: red;
          font-size: 21px;
          font-weight: bold;
          margin-top: 15px;
          text-align: center;
        "
      >
        Please check with airline before issuance
      </p>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WaitlistDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
});
</script>
