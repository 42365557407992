<template>
  <ag-modal
    persistent
    :is-open="isOpen"
    class="change_search_modal"
    modal-width="30%"
  >
    <template #header>
      <a-g-button
        type="button"
        class="modal_close_icon"
        variant="link"
        @click="handleClose"
        >Close</a-g-button
      >
    </template>
    <template #body>
      <AgDiv test-id="" class="text-center">
        <AgAlertBox test-id="" :alert-type="alertType" class="modal_alert" />
        <AgIcon test-id="" name="" class="margin_bottom_20" />
        <AgHeading :title="title" variant="h2" test-id="" />
        <AgHeading :title="description" variant="p" test-id="" />
        <AgHeading :title="renderDate" variant="h3" test-id="" />
        <AGButton
          test-id=""
          @click="handleClose"
          variant="primary"
          type="button"
          >OK</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import {
  popupAlertBus,
  POPUP_ALERT_EVENTS,
} from "../eventBusses/popupAlertBus";

export default defineComponent({
  name: "AgPopupAlert",
  data() {
    return {
      isOpen: false,
      title: "",
      description: "",
      alertType: "error",
    };
  },
  computed: {
    renderDate() {
      return format(new Date(), "yyyy-MM-dd HH:mm");
    },
  },
  methods: {
    handleClose() {
      this.isOpen = false;
    },
  },
  mounted() {
    popupAlertBus.on(POPUP_ALERT_EVENTS.POPUP_ALERT, (payload: any) => {
      this.isOpen = true;
      this.title = payload?.title
        ? payload?.title
        : payload?.type
        ? _.capitalize(payload?.type)
        : "error";
      this.description = payload?.message;
      this.alertType = payload?.type ? payload?.type : "error";
    });
  },
  unmounted() {
    popupAlertBus.off(POPUP_ALERT_EVENTS.POPUP_ALERT);
  },
});
</script>
