import Localbase from "localbase";

let instance = null;

export default function getLocalbaseInstance() {
  if (!instance) {
    instance = new Localbase("db");
  }
  return instance;
}
