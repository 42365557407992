const NATIONALITIES = [
  { label: "Afghan", value: "AF" },
  { label: "\u00c5land Island", value: "AX" },
  { label: "Albanian", value: "AL" },
  { label: "Algerian", value: "DZ" },
  { label: "American Samoan", value: "AS" },
  { label: "Andorran", value: "AD" },
  { label: "Angolan", value: "AO" },
  { label: "Anguillan", value: "AI" },
  { label: "Antarctic", value: "AQ" },
  { label: "Antiguan or Barbudan", value: "AG" },
  { label: "Argentine", value: "AR" },
  { label: "Armenian", value: "AM" },
  { label: "Aruban", value: "AW" },
  { label: "Australian", value: "AU" },
  { label: "Austrian", value: "AT" },
  { label: "Azerbaijani, Azeri", value: "AZ" },
  { label: "Bahamian", value: "BS" },
  { label: "Bahraini", value: "BH" },
  { label: "Bangladeshi", value: "BD" },
  { label: "Barbadian", value: "BB" },
  { label: "Belarusian", value: "BY" },
  { label: "Belgian", value: "BE" },
  { label: "Belizean", value: "BZ" },
  { label: "Beninese, Beninois", value: "BJ" },
  { label: "Bermudian, Bermudan", value: "BM" },
  { label: "Bhutanese", value: "BT" },
  { label: "Bolivian", value: "BO" },
  { label: "Bonaire", value: "BQ" },
  { label: "Bosnian or Herzegovinian", value: "BA" },
  { label: "Motswana, Botswanan", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazilian", value: "BR" },
  { label: "BIOT", value: "IO" },
  { label: "Bruneian", value: "BN" },
  { label: "Bulgarian", value: "BG" },
  { label: "Burkinab\u00e9", value: "BF" },
  { label: "Burundian", value: "BI" },
  { label: "Cabo Verdean", value: "CV" },
  { label: "Cambodian", value: "KH" },
  { label: "Cameroonian", value: "CM" },
  { label: "Canadian", value: "CA" },
  { label: "Caymanian", value: "KY" },
  { label: "Central African", value: "CF" },
  { label: "Chadian", value: "TD" },
  { label: "Chilean", value: "CL" },
  { label: "Chinese", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos Island", value: "CC" },
  { label: "Colombian", value: "CO" },
  { label: "Comoran, Comorian", value: "KM" },
  { label: "Congolese", value: "CG" },
  { label: "Congolese", value: "CD" },
  { label: "Cook Island", value: "CK" },
  { label: "Costa Rican", value: "CR" },
  { label: "Ivorian", value: "CI" },
  { label: "Croatian", value: "HR" },
  { label: "Cuban", value: "CU" },
  { label: "Cura\u00e7aoan", value: "CW" },
  { label: "Cypriot", value: "CY" },
  { label: "Czech", value: "CZ" },
  { label: "Danish", value: "DK" },
  { label: "Djiboutian", value: "DJ" },
  { label: "Dominican", value: "DM" },
  { label: "Dominican", value: "DO" },
  { label: "Ecuadorian", value: "EC" },
  { label: "Egyptian", value: "EG" },
  { label: "Salvadoran", value: "SV" },
  { label: "Equatorial Guinean, Equatoguinean", value: "GQ" },
  { label: "Eritrean", value: "ER" },
  { label: "Estonian", value: "EE" },
  { label: "Ethiopian", value: "ET" },
  { label: "Falkland Island", value: "FK" },
  { label: "Faroese", value: "FO" },
  { label: "Fijian", value: "FJ" },
  { label: "Finnish", value: "FI" },
  { label: "French", value: "FR" },
  { label: "French Guianese", value: "GF" },
  { label: "French Polynesian", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabonese", value: "GA" },
  { label: "Gambian", value: "GM" },
  { label: "Georgian", value: "GE" },
  { label: "German", value: "DE" },
  { label: "Ghanaian", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greek, Hellenic", value: "GR" },
  { label: "Greenlandic", value: "GL" },
  { label: "Grenadian", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guamanian, Guambat", value: "GU" },
  { label: "Guatemalan", value: "GT" },
  { label: "Channel Island", value: "GG" },
  { label: "Guinean", value: "GN" },
  { label: "Bissau-Guinean", value: "GW" },
  { label: "Guyanese", value: "GY" },
  { label: "Haitian", value: "HT" },
  { label: "Heard Island or McDonald Islands", value: "HM" },
  { label: "Vatican", value: "VA" },
  { label: "Honduran", value: "HN" },
  { label: "Hong Kong, Hong Kongese", value: "HK" },
  { label: "Hungarian, Magyar", value: "HU" },
  { label: "Icelandic", value: "IS" },
  { label: "Indian", value: "IN" },
  { label: "Indonesian", value: "ID" },
  { label: "Iranian, Persian", value: "IR" },
  { label: "Iraqi", value: "IQ" },
  { label: "Irish", value: "IE" },
  { label: "Manx", value: "IM" },
  { label: "Israeli", value: "IL" },
  { label: "Italian", value: "IT" },
  { label: "Jamaican", value: "JM" },
  { label: "Japanese", value: "JP" },
  { label: "Channel Island", value: "JE" },
  { label: "Jordanian", value: "JO" },
  { label: "Kazakhstani, Kazakh", value: "KZ" },
  { label: "Kenyan", value: "KE" },
  { label: "I-Kiribati", value: "KI" },
  { label: "North Korean", value: "KP" },
  { label: "South Korean", value: "KR" },
  { label: "Kuwaiti", value: "KW" },
  { label: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz", value: "KG" },
  { label: "Lao, Laotian", value: "LA" },
  { label: "Latvian", value: "LV" },
  { label: "Lebanese", value: "LB" },
  { label: "Basotho", value: "LS" },
  { label: "Liberian", value: "LR" },
  { label: "Libyan", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuanian", value: "LT" },
  { label: "Luxembourg, Luxembourgish", value: "LU" },
  { label: "Macanese, Chinese", value: "MO" },
  { label: "Macedonian", value: "MK" },
  { label: "Malagasy", value: "MG" },
  { label: "Malawian", value: "MW" },
  { label: "Malaysian", value: "MY" },
  { label: "Maldivian", value: "MV" },
  { label: "Malian, Malinese", value: "ML" },
  { label: "Maltese", value: "MT" },
  { label: "Marshallese", value: "MH" },
  { label: "Martiniquais, Martinican", value: "MQ" },
  { label: "Mauritanian", value: "MR" },
  { label: "Mauritian", value: "MU" },
  { label: "Mahoran", value: "YT" },
  { label: "Mexican", value: "MX" },
  { label: "Micronesian", value: "FM" },
  { label: "Moldovan", value: "MD" },
  { label: "Mon\u00e9gasque, Monacan", value: "MC" },
  { label: "Mongolian", value: "MN" },
  { label: "Montenegrin", value: "ME" },
  { label: "Montserratian", value: "MS" },
  { label: "Moroccan", value: "MA" },
  { label: "Mozambican", value: "MZ" },
  { label: "Burmese", value: "MM" },
  { label: "Namibian", value: "NA" },
  { label: "Nauruan", value: "NR" },
  { label: "Nepali, Nepalese", value: "NP" },
  { label: "Dutch, Netherlandic", value: "NL" },
  { label: "New Caledonian", value: "NC" },
  { label: "New Zealand, NZ", value: "NZ" },
  { label: "Nicaraguan", value: "NI" },
  { label: "Nigerien", value: "NE" },
  { label: "Nigerian", value: "NG" },
  { label: "Niuean", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Marianan", value: "MP" },
  { label: "Norwegian", value: "NO" },
  { label: "Omani", value: "OM" },
  { label: "Pakistani", value: "PK" },
  { label: "Palauan", value: "PW" },
  { label: "Palestinian", value: "PS" },
  { label: "Panamanian", value: "PA" },
  { label: "Papua New Guinean, Papuan", value: "PG" },
  { label: "Paraguayan", value: "PY" },
  { label: "Peruvian", value: "PE" },
  { label: "Philippine, Filipino", value: "PH" },
  { label: "Pitcairn Island", value: "PN" },
  { label: "Polish", value: "PL" },
  { label: "Portuguese", value: "PT" },
  { label: "Puerto Rican", value: "PR" },
  { label: "Qatari", value: "QA" },
  { label: "R\u00e9unionese, R\u00e9unionnais", value: "RE" },
  { label: "Romanian", value: "RO" },
  { label: "Russian", value: "RU" },
  { label: "Rwandan", value: "RW" },
  { label: "Barth\u00e9lemois", value: "BL" },
  { label: "Saint Helenian", value: "SH" },
  { label: "Kittitian or Nevisian", value: "KN" },
  { label: "Saint Lucian", value: "LC" },
  { label: "Saint-Martinoise", value: "MF" },
  { label: "Saint-Pierrais or Miquelonnais", value: "PM" },
  { label: "Saint Vincentian, Vincentian", value: "VC" },
  { label: "Samoan", value: "WS" },
  { label: "Sammarinese", value: "SM" },
  { label: "S\u00e3o Tom\u00e9an", value: "ST" },
  { label: "Saudi, Saudi Arabian", value: "SA" },
  { label: "Senegalese", value: "SN" },
  { label: "Serbian", value: "RS" },
  { label: "Seychellois", value: "SC" },
  { label: "Sierra Leonean", value: "SL" },
  { label: "Singaporean", value: "SG" },
  { label: "Sint Maarten", value: "SX" },
  { label: "Slovak", value: "SK" },
  { label: "Slovenian, Slovene", value: "SI" },
  { label: "Solomon Island", value: "SB" },
  { label: "Somali, Somalian", value: "SO" },
  { label: "South African", value: "ZA" },
  { label: "South Georgia or South Sandwich Islands", value: "GS" },
  { label: "South Sudanese", value: "SS" },
  { label: "Spanish", value: "ES" },
  { label: "Sri Lankan", value: "LK" },
  { label: "Sudanese", value: "SD" },
  { label: "Surinamese", value: "SR" },
  { label: "Svalbard", value: "SJ" },
  { label: "Swazi", value: "SZ" },
  { label: "Swedish", value: "SE" },
  { label: "Swiss", value: "CH" },
  { label: "Syrian", value: "SY" },
  { label: "Chinese, Taiwanese", value: "TW" },
  { label: "Tajikistani", value: "TJ" },
  { label: "Tanzanian", value: "TZ" },
  { label: "Thai", value: "TH" },
  { label: "Timorese", value: "TL" },
  { label: "Togolese", value: "TG" },
  { label: "Tokelauan", value: "TK" },
  { label: "Tongan", value: "TO" },
  { label: "Trinidadian or Tobagonian", value: "TT" },
  { label: "Tunisian", value: "TN" },
  { label: "Turkish", value: "TR" },
  { label: "Turkmen", value: "TM" },
  { label: "Turks and Caicos Island", value: "TC" },
  { label: "Tuvaluan", value: "TV" },
  { label: "Ugandan", value: "UG" },
  { label: "Ukrainian", value: "UA" },
  { label: "Emirati, Emirian, Emiri", value: "AE" },
  { label: "British, UK", value: "GB" },
  { label: "American", value: "UM" },
  { label: "American", value: "US" },
  { label: "Uruguayan", value: "UY" },
  { label: "Uzbekistani, Uzbek", value: "UZ" },
  { label: "Ni-Vanuatu, Vanuatuan", value: "VU" },
  { label: "Venezuelan", value: "VE" },
  { label: "Vietnamese", value: "VN" },
  { label: "British Virgin Island", value: "VG" },
  { label: "U.S. Virgin Island", value: "VI" },
  { label: "Wallis and Futuna, Wallisian or Futunan", value: "WF" },
  { label: "Sahrawi, Sahrawian, Sahraouian", value: "EH" },
  { label: "Yemeni", value: "YE" },
  { label: "Zambian", value: "ZM" },
  { label: "Zimbabwean", value: "ZW" },
];

export default NATIONALITIES;
