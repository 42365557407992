import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AirportSearchAutoComplete = _resolveComponent("AirportSearchAutoComplete")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_ag_icon = _resolveComponent("ag-icon")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_calendar = _resolveComponent("ag-calendar")!
  const _component_a_g_button = _resolveComponent("a-g-button")!

  return (_openBlock(), _createBlock(_component_ag_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "6"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgDiv, { class: "combine_flight" }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_row, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_column, {
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AirportSearchAutoComplete, {
                        "onUpdate:origin": _ctx.searchOriginAirportQuery,
                        "onOnSelect:origin": _ctx.changeOrigin,
                        label: "From",
                        name: "origin",
                        items: _ctx.renderOriginPlaces,
                        value: _ctx.$store.state.flightModule.origin,
                        error: _ctx.errors?.origin
                      }, null, 8, ["onUpdate:origin", "onOnSelect:origin", "items", "value", "error"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AGButton, {
                    class: _normalizeClass(["compare_icon", { flipped: true }]),
                    style: {"background-color":"var(--green-color) !important"},
                    onClick: _ctx.swapFields,
                    variant: "link"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_icon, { name: "compareIcon" })
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_ag_column, {
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AirportSearchAutoComplete, {
                        "onUpdate:destination": _ctx.searchDestinationAirportQuery,
                        "onOnSelect:destination": _ctx.changeDestination,
                        label: "To",
                        name: "destination",
                        items: _ctx.renderDestinationPlaces,
                        value: _ctx.$store.state.flightModule.destination,
                        error: _ctx.errors?.destination
                      }, null, 8, ["onUpdate:destination", "onOnSelect:destination", "items", "value", "error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "6",
        cols: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgDiv, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_column, {
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_calendar, {
                        label: "Departure Date",
                        modelValue: _ctx.$store.state.flightModule.departure_date,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store.state.flightModule.departure_date) = $event)),
                        "min-date": new Date(),
                        date: _ctx.$store.state.flightModule.departure_date,
                        "onUpdate:departureDate": _ctx.changeDepartureDate,
                        "calendar-name": "departureDate",
                        IconName: "departureFlightIcon"
                      }, null, 8, ["modelValue", "min-date", "date", "onUpdate:departureDate"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ag_column, {
                    xs: "12",
                    sm: "12",
                    md: "6",
                    cols: "12"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_calendar, {
                        label: "Return Date",
                        modelValue: _ctx.$store.state.flightModule.return_date,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.flightModule.return_date) = $event)),
                        date: _ctx.$store.state.flightModule.return_date,
                        "min-date": _ctx.$store.state.flightModule.departure_date,
                        disabled: _ctx.disableReturnDatePicker,
                        "onUpdate:returnDate": _ctx.changeReturnDate,
                        "calendar-name": "returnDate",
                        IconName: "departureFlightIcon"
                      }, null, 8, ["modelValue", "date", "min-date", "disabled", "onUpdate:returnDate"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ag_column, {
        xs: "12",
        sm: "12",
        md: "12"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgDiv, {
            "test-id": "",
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_g_button, {
                onClick: _ctx.onFlightSearch,
                isLoading: _ctx.$store.getters.isFlightsLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Search Flights")
                ]),
                _: 1
              }, 8, ["onClick", "isLoading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}