export const STORAGE_KEYS = {
  ACCESS_TOKEN: "access-token",
  REFRESH_TOKEN: "refresh-token",
  USER: "user",
  SETTINGS: "settings",
  ORGANIZATION: "organization",
  PREFERENCES: "preferences",
  PERMISSIONS: "permissions",
  LAST_FETCHED_FEATURED_NEWS_TIME: "last-fetched-featured-news-time",
};
