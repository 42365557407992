import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectedFlightLegItem = _resolveComponent("SelectedFlightLegItem")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!

  return (_openBlock(), _createBlock(_component_AgRow, { "test-id": "" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFlightLegs, (booking, index) => {
        return (_openBlock(), _createBlock(_component_AgColumn, {
          "test-id": "",
          sm: "6",
          lg: "6",
          md: "6",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SelectedFlightLegItem, {
              "selected-leg": booking,
              index: index
            }, null, 8, ["selected-leg", "index"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}