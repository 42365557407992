import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgTripSummary = _resolveComponent("AgTripSummary")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legs, (leg, cardIndex) => {
    return (_openBlock(), _createBlock(_component_ag_card, { key: cardIndex }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(leg.segment, (segment, index) => {
          return (_openBlock(), _createBlock(_component_AgTripSummary, {
            key: index,
            "test-id": "",
            count: cardIndex + 1,
            "departure-date": _ctx.renderDate(segment?.departure_datetime),
            "arrival-date": _ctx.renderDate(segment?.arrival_datetime),
            "from-destination-code": segment?.origin?.iata_code,
            "from-destination-name": segment?.origin?.city,
            "from-destination-time": _ctx.renderDate(segment?.departure_datetime, 'HH:mm'),
            "to-destination-code": segment?.destination?.iata_code,
            "to-destination-name": segment?.destination?.city,
            "to-destination-time": _ctx.renderDate(segment?.arrival_datetime, 'HH:mm')
          }, null, 8, ["count", "departure-date", "arrival-date", "from-destination-code", "from-destination-name", "from-destination-time", "to-destination-code", "to-destination-name", "to-destination-time"]))
        }), 128))
      ]),
      _: 2
    }, 1024))
  }), 128))
}