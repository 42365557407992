export enum TravelerCardDetails {
  PHONE_NUMBER = "Phone Number",
  TITLE = "Title",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  DOB = "Date Of Birth",
  NATIONALITY = "Nationality",
  PASSPORT_EXPIRY = "Passport Expiry",
  PASSPORT_NUMBER = "Passport Number",
  GENDER = "Gender",
  CNIC_NUMBER = "CNIC Number",
}
