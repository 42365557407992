<template>
  <AgDiv class="flightMainScreen" test-id="flightscreen01">
    <ag-card> <FlightSearchComponent /> </ag-card>
  </AgDiv>

  <hr />
  <ag-heading variant="h3" title="Continue Your Search" />
  <ag-heading
    variant="p"
    title="Showing results based on your search history"
  />
  <AgRow test-id="">
    <AgColumn test-id="" class="d-flex">
      <p v-show="searchItemsLoading">Loading...</p>
      <p v-show="renderEmptyMessage">No Result Found!</p>
      <AgFlightContinueSearch
        v-for="(item, index) in contineSearchItems"
        @click="handleContineSearchClick(item)"
        :key="index"
        :flightType="renderFlightType(item?.route_type)"
        :from="item?.origin"
        :to="item?.destination"
        :date="formatDate(item?.departure_date)"
        :adult="item?.traveler_count?.adult_count"
        :infant="item?.traveler_count?.infant_count"
        :children="item?.traveler_count?.child_count"
        :flight-class="item?.cabin_class"
      />
    </AgColumn>
  </AgRow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isBefore } from "date-fns";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import getLocalbaseInstance from "@/modules/Flight/constants/localbase.js";
import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";
import FlightSearchComponent from "@/ag-flight-components/components/FlightSearch/index.vue";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { ROUTE_TYPE } from "@/ag-flight-components/enums/route_type";

export default defineComponent({
  name: "FlightSearch",
  components: {
    FlightSearchComponent,
  },
  data(): { contineSearchItems: any[]; searchItemsLoading: boolean } {
    return {
      contineSearchItems: [],
      searchItemsLoading: false,
    };
  },
  computed: {
    renderEmptyMessage(): boolean {
      return !this.searchItemsLoading && !this.contineSearchItems.length;
    },
  },
  methods: {
    async continueSearchItems() {
      const db = getLocalbaseInstance();
      this.searchItemsLoading = true;
      try {
        const response = await db.collection("flights").get();
        const uniqueData = new Set();
        const currentDate = new Date();
        const filteredAndUniqueData = response
          .sort((a: any, b: any) => b?.timestamp - a?.timestamp)
          .filter((item: any) => {
            const key = item.path;
            if (!uniqueData.has(key)) {
              uniqueData.add(key);
              return true;
            }
            return false;
          });

        console.log("filteredAndUniqueData", filteredAndUniqueData);
        await db.collection("flights").set(filteredAndUniqueData);
        this.contineSearchItems = filteredAndUniqueData as any[];
      } catch (e) {
        console.log("Error", e);
      } finally {
        this.searchItemsLoading = false;
      }
    },
    formatDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MM_YYYY_WITH_SPACE);
    },
    renderFlightType(flightType: string) {
      return flightType === ROUTE_TYPE.ONEWAY ? "oneWayFlight" : "returnFlight";
    },
    handleContineSearchClick(item: any) {
      this.$router.push(item?.path);
    },
  },
  async mounted() {
    await this.continueSearchItems();
  },
  beforeMount() {
    this.$store.dispatch("fetchAirports", "Pakistan");
  },
});
</script>
