import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72e3bbbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "m-icon"
}
const _hoisted_3 = {
  key: 1,
  class: "m-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.buttonClasses),
    disabled: _ctx.disabled
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.icon), 1))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}