import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgFlightChip = _resolveComponent("AgFlightChip")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!

  return (_openBlock(), _createBlock(_component_AgRow, { "test-id": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_AgColumn, {
        "test-id": "",
        xs: "12",
        sm: "8",
        md: "8"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgDiv, {
            "test-id": "",
            class: "d-flex margin_bottom_10",
            style: {"flex-wrap":"wrap"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedAirlineNames, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgFlightChip, {
                  onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'airlines')),
                  key: index,
                  value: item,
                  "test-id": ""
                }, null, 8, ["onClick", "value"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedStops, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgFlightChip, {
                  onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'stops')),
                  key: index,
                  value: item,
                  "test-id": ""
                }, null, 8, ["onClick", "value"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDepartureTime, (item, index) => {
                return (_openBlock(), _createBlock(_component_AgFlightChip, {
                  onClick: ($event: any) => (_ctx.handleRemoveChip(index, 'departureTime')),
                  key: index,
                  value: item,
                  "test-id": ""
                }, null, 8, ["onClick", "value"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_AgColumn, {
        "test-id": "",
        xs: "12",
        sm: "4",
        md: "4"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "fare_calculator")
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}