<template>
  <AgRow test-id="">
    <AgColumn test-id="" xs="12" sm="8" md="8">
      <AgDiv test-id="" class="d-flex margin_bottom_10" style="flex-wrap: wrap">
        <AgFlightChip
          v-for="(item, index) in selectedAirlineNames"
          @click="handleRemoveChip(index, 'airlines')"
          :key="index"
          :value="item"
          test-id=""
        />
        <AgFlightChip
          v-for="(item, index) in selectedStops"
          @click="handleRemoveChip(index, 'stops')"
          :key="index"
          :value="item"
          test-id=""
        />
        <AgFlightChip
          v-for="(item, index) in selectedDepartureTime"
          @click="handleRemoveChip(index, 'departureTime')"
          :key="index"
          :value="item"
          test-id=""
        />
      </AgDiv>
    </AgColumn>
    <AgColumn test-id="" xs="12" sm="4" md="4">
      <slot name="fare_calculator"></slot>
    </AgColumn>
  </AgRow>
</template>

<script lang="ts">
import {
  FlightBaseFilter,
  SelectedFlightSearchQueryFilters,
} from "@/ag-flight-components/types/Flights";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "FlightSearchChips",
  props: {
    handleRemoveChip: {
      type: Function,
      required: true,
    },
    selectedFilters: {
      type: Object as PropType<SelectedFlightSearchQueryFilters>,
      required: true,
    },
  },
  computed: {
    selectedAirlineNames() {
      const filters = this.$store.getters.filters;
      const selectedAirlines: Array<string> = this.selectedFilters.airlines;

      if (filters.airlines.length === selectedAirlines.length) {
        return ["All airlines"];
      }
      return filters.airlines
        .filter((airline: FlightBaseFilter) =>
          selectedAirlines.includes(airline.value)
        )
        .map((airline: FlightBaseFilter) => airline.label);
    },
    selectedStops() {
      const filters = this.$store.getters.filters;
      const selectedStops: Array<string> = this.selectedFilters.stops;

      if (filters.stops.length === selectedStops.length) {
        return ["All stops"];
      }
      return filters.stops
        .filter((stop: FlightBaseFilter) => selectedStops.includes(stop.value))
        .map((stop: FlightBaseFilter) => stop.label);
    },
    selectedDepartureTime() {
      const filters = this.$store.getters.filters;
      const selectedDepartureTime: Array<string> =
        this.selectedFilters.departureTime;

      if (filters.departureTime.length === selectedDepartureTime.length) {
        return ["All departure times"];
      }
      return filters.departureTime
        .filter((departureTime: string) =>
          selectedDepartureTime.includes(departureTime)
        )
        .map((departureTime: string) => departureTime);
    },
  },
});
</script>
