import { TravelerTypes } from "@/ag-flight-components/constants/TravelerTypes";

export const BookingFormTravelerData = {
  title: null,
  first_name: null,
  last_name: null,
  dob: null,
  gender: null,
  passenger_type: null,
  nationality: "PK",
  is_international: false,
  passport_number: null,
  passport_expiry: null,
  cnic: null,
};

export const titles = [
  { label: "Mr", value: "MR", type: TravelerTypes.ADULT },
  { label: "Mrs", value: "MRS", type: TravelerTypes.ADULT },
  { label: "Ms", value: "MS", type: TravelerTypes.ADULT },
  { label: "Master", value: "MASTER", type: TravelerTypes.CHILD },
  { label: "Ms", value: "MS", type: TravelerTypes.CHILD },
  { label: "Master", value: "MASTER", type: TravelerTypes.INFANT },
  { label: "Ms", value: "MS", type: TravelerTypes.INFANT },
];

export const genders = [
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Others", value: "O" },
];
