<template>
  <AgDiv class="widget_border">
    <AgRow test-id="">
      <AgColumn test-id="" sm="4" cols="12">
        <AgContactInfo icon-name="pakFlag" :title="renderFullName" />
      </AgColumn>
      <AgColumn test-id="" sm="4" cols="12">
        <AgContactInfo
          icon-name="nicIcon"
          :title="renderPassportNumberandExpiry.number"
          :sub-title="renderPassportNumberandExpiry.expiry"
        />
      </AgColumn>
      <AgColumn test-id="" sm="4" cols="12">
        <AgContactInfo
          :icon-name="renderPassengerType.icon"
          :title="renderPassengerType.type"
        />
      </AgColumn>
    </AgRow>
  </AgDiv>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

import { Passenger } from "@/ag-flight-components/types";
import {
  TravelerTypes,
  getTravelerTypeName,
} from "@/ag-flight-components/constants/TravelerTypes";

export default defineComponent({
  name: "PassengerDetailCard",
  props: {
    passenger: { type: Object as PropType<Passenger> },
    isInternational: { type: Boolean, default: false },
  },
  computed: {
    renderFullName() {
      return `${this.passenger?.title} ${this.passenger?.first_name} ${this.passenger?.last_name}`;
    },
    renderPassportNumberandExpiry() {
      if (this.isInternational) {
        const expiryDate = this.passenger?.passport_expiry_date || new Date();
        return {
          number: this.passenger?.passport_number,
          expiry: `Expiry: ${new Date(expiryDate).toLocaleDateString()}`,
        };
      }
      return {
        number: "-",
        expiry: "",
      };
    },
    renderPassengerType() {
      const type = getTravelerTypeName(
        this.passenger?.passenger_type as TravelerTypes
      );
      return {
        type,
        icon: `${type?.toLowerCase()}Icon`,
      };
    },
  },
});
</script>
