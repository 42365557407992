import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20bb7b35"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "has-error"]
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value", "type", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "m-textfield",
    disabled: _ctx.disabled,
    "has-error": _ctx.hasError
  }, [
    _createElementVNode("label", {
      for: _ctx.inputId,
      class: _normalizeClass(["m-label", { 'm-active': _ctx.isActiveTypes ? _ctx.isActive : true }])
    }, _toDisplayString(_ctx.label), 11, _hoisted_2),
    _createElementVNode("input", {
      id: _ctx.inputId,
      value: _ctx.inputValue,
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInput($event))),
      class: "m-input",
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      readonly: _ctx.disabled
    }, null, 40, _hoisted_3),
    _withDirectives(_createElementVNode("div", { class: "m-input-error-text" }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, _ctx.hasError]
    ])
  ], 8, _hoisted_1))
}