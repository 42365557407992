import { format } from "date-fns";

import { Place } from "../types/Place";
import { MultiCityLegStateType, FlightOption } from "../types/Flights";
import { TimeUtility } from "./TimeUtility";
import { ROUTE_TYPE } from "../enums/route_type";
import {
  FORMAT_MMM_D,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { BaggageInfo } from "../types";
import { utcToZonedTime } from "date-fns-tz";

export function calculatePriceRangeFilters(
  flightOptions: FlightOption[]
): Array<number> {
  let fareOptions: Array<number> = [];
  flightOptions.forEach((flightOption) =>
    flightOption.fare_options.forEach((fareOption) =>
      fareOptions.push(fareOption.price.gross_fare.value)
    )
  );
  fareOptions = fareOptions.sort((a, b) => a - b);
  return [fareOptions[0], fareOptions[fareOptions.length - 1]];
}

export function getAirlineFilters(
  flightOptions: FlightOption[]
): Array<{ label: string; value: string }> {
  const airlinesCount: any = {};
  flightOptions.forEach((flightOption) => {
    if (airlinesCount[flightOption.airline.code]) {
      airlinesCount[flightOption.airline.code].count++;
    } else {
      airlinesCount[flightOption.airline.code] = {
        name: flightOption.airline.name,
        code: flightOption.airline.code,
        count: 1,
      };
    }
  });
  return Object.values(airlinesCount).map((item: any) => ({
    label: `${item.name} (${item.count})`,
    value: item.code || "",
  }));
}

export function filterFlightsByPrice(
  flightOptions: FlightOption[],
  minPriceRange: number,
  maxPriceRange: number
) {
  return flightOptions.filter((flightOption) => {
    const fareOptions = flightOption.fare_options.filter((fareOption) => {
      const grossFare = fareOption.price.gross_fare.value;
      return grossFare >= minPriceRange && grossFare <= maxPriceRange;
    });
    return !!fareOptions.length;
  });
}

export function filterFlightsByAirlines(
  flightOptions: FlightOption[],
  airlines: Array<string> = []
) {
  let tempFilteredData: Array<FlightOption> = [];
  tempFilteredData = flightOptions.filter((item) =>
    airlines.includes(item.airline.code)
  );
  return tempFilteredData;
}

export function filterFlightsByStops(
  flightOptions: FlightOption[],
  stops: string[] = []
): FlightOption[] {
  return flightOptions
    .map((flightOption) => ({
      ...flightOption,
      fare_options: flightOption.fare_options.filter((fareOption) => {
        const numStops = fareOption.segments.length;
        return (
          stops.includes(numStops.toString()) ||
          (stops.includes("3") && numStops > 2)
        );
      }),
    }))
    .filter((flightOption) => flightOption.fare_options.length > 0);
}

export const filterFlightsByDepartureTime = (
  flightOptions: FlightOption[],
  departureTime: string[] = []
) => {
  const departureHours = departureTime.flatMap((time) => {
    const [start, end] = time.split("-").map(Number);
    return Array.from({ length: end - start + 1 }, (_, i) =>
      (start + i).toString().padStart(2, "0")
    );
  });

  return flightOptions.filter((flightOption) => {
    const flightDepartureHour = TimeUtility.parsedFlightTimeorDate(
      flightOption.departure_time,
      "HH"
    );
    return departureHours.includes(flightDepartureHour);
  });
};

export const mapFlightAirport = (airport: Place) => ({
  ...airport,
  label: `${airport.city} ${airport.iata_code}`,
});

export const getCurrencyFormatter = (currency = "PKR") => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
  });
};

export const renderBaggageInfo = (baggage_info: BaggageInfo) => {
  const { pieces, weight, unit } = baggage_info;

  if (pieces > 0) {
    const baggageText = `${pieces} ${pieces > 1 ? "pieces" : "piece"}`;

    return weight > 0
      ? `${baggageText} of ${weight} ${unit}`
      : `${baggageText} (As per airline policy)`;
  } else if (pieces < 1 && weight < 1) {
    return "Baggage included (As per airline policy)";
  } else {
    return `${weight} ${unit}`;
  }
};

export const formatMultiCityQueryParam = (
  data: Array<MultiCityLegStateType>
) => {
  return data.reduce((acc, curr: MultiCityLegStateType) => {
    const formatedDate = format(curr.departure_date, FORMAT_YYY_MM_DD);

    const temp = `${curr.origin!.iata_code},${
      curr?.destination!.iata_code
    },${formatedDate},`;
    acc += temp;

    return acc;
  }, "");
};

export const formatMultiCityQueryParamToArray = (trips: string) => {
  return trips
    .split(",")
    .reduce((acc, curr: string, index: number, array: string[]) => {
      if (index % 3 === 0) {
        acc.push({
          origin: curr,
          destination: array[index + 1],
          departure_date: array[index + 2],
        });
      }
      return acc;
    }, [] as { origin: string; destination: string; departure_date: string }[]);
};

export const formatTripDates = (trips: string, route_type: string) => {
  if (route_type === ROUTE_TYPE.MULTI_CITY) {
    const formattedTrips = formatMultiCityQueryParamToArray(trips as string);
    const startDate = formattedTrips[0].departure_date;
    const endDate = formattedTrips[formattedTrips.length - 1].departure_date;
    return `${format(new Date(startDate), FORMAT_MMM_D)} - ${format(
      new Date(endDate),
      FORMAT_MMM_D
    )}`;
  } else {
    // Don't delete origin and destination variables because there string is splited
    const [origin, destination, departure_date, return_date] = trips.split(",");
    if (return_date) {
      return `${format(new Date(departure_date), FORMAT_MMM_D)} - ${format(
        new Date(return_date),
        FORMAT_MMM_D
      )}`;
    } else {
      return `${format(new Date(departure_date), FORMAT_MMM_D)}`;
    }
  }
};

export const handleConvertUTCtoZonedTime = (date: Date): string => {
  const ALLOWED_TIMEZONES = ["Asia/Dubai", "Asia/Riyadh"];

  const currentTimezone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const validTimezone = ALLOWED_TIMEZONES.includes(currentTimezone)
    ? currentTimezone
    : "Asia/Karachi";
  const convertedDate = utcToZonedTime(date, validTimezone);
  const formattedDate = convertedDate.toISOString();

  return formattedDate;
};
