export const FORMAT_YYY_MM_DD = "yyyy-MM-dd";
export const FORMAT_DD_MMM_YYYY = "dd/MMM/yyyy";
export const FORMAT_HH_SS = "hh:mm";
export const FORMAT_HH_SS_A = "hh:mm a";
export const FORMAT_DD_MMM_YYYY_HH_SS_A = "dd/MMM/yyyy hh:mm a";
export const FORMAT_YYY_MM_DD_HH_SS_A = "yyyy-MM-dd hh:mm a";
export const FORMAT_MMMM_DD_YYYY = "MMMM dd, yyyy";
export const FORMAT_DD_MMM = "dd MMM";
export const FORMAT_DD_MMM_YYYY_WITH_DASH = "dd-MMM-yyyy";
export const FORMAT_DD_MM_YYYY_HH_MM = "dd-MMM-yyyy hh:mm";
export const FORMAT_DD_MM_YYYY_HH_MM_24_HOURS = "dd-MMM-yyyy HH:mm";
export const FORMAT_DD_MM_YYYY_WITH_SPACE = "dd MMM, yyyy";
export const FORMAT_MMM_D = "MMM d";

export const FORMAT_DD_MM_YYYY_HH_SS_A_WITH_SPACE = "dd MMM, yyyy hh:mm a";
