import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AGDropDownButtonItem = _resolveComponent("AGDropDownButtonItem")!
  const _component_AGButtonDropDown = _resolveComponent("AGButtonDropDown")!
  const _component_AgTravelerCountItem = _resolveComponent("AgTravelerCountItem")!
  const _component_AgTravelerCountDropdown = _resolveComponent("AgTravelerCountDropdown")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createBlock(_component_AgDiv, {
    class: "d-flex justify-content-end flight_dropdown_parent_flight flex-wrap margin_bottom_20",
    "test-id": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AGButtonDropDown, {
        class: "flight_dropdown",
        items: _ctx.routeTypeOptions
      }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.renderRouteTypeLabel), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeTypeOptions, (item, index) => {
            return (_openBlock(), _createBlock(_component_AGDropDownButtonItem, {
              key: index,
              name: item.label,
              onClick: ($event: any) => (_ctx.changeRouteType(item))
            }, null, 8, ["name", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["items"]),
      _createVNode(_component_AGButtonDropDown, {
        class: "flight_dropdown",
        items: _ctx.cabinClassOptions
      }, {
        label: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.renderCabinClassLabel()), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cabinClassOptions, (item, index) => {
            return (_openBlock(), _createBlock(_component_AGDropDownButtonItem, {
              key: index,
              name: item.label,
              onClick: ($event: any) => (_ctx.changeCabinClass(item))
            }, null, 8, ["name", "onClick"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["items"]),
      _createVNode(_component_AgTravelerCountDropdown, {
        "test-id": "",
        label: _ctx.travelerCountLabel
      }, {
        Items: _withCtx(() => [
          _createVNode(_component_AgTravelerCountItem, {
            icon: "adultIcon",
            name: "AdultCount",
            "onUpdate:AdultCount": _ctx.updateAdultCount,
            count: _ctx.$store.state.flightModule.adult_count,
            label: "Adult (12+)",
            "test-id": ""
          }, null, 8, ["onUpdate:AdultCount", "count"]),
          _createVNode(_component_AgTravelerCountItem, {
            icon: "childrenIcon",
            label: "Children",
            name: "ChildCount",
            "onUpdate:ChildCount": _ctx.updateChildCount,
            count: _ctx.$store.state.flightModule.child_count,
            hint: "(2 to less then 12)",
            "test-id": ""
          }, null, 8, ["onUpdate:ChildCount", "count"]),
          _createVNode(_component_AgTravelerCountItem, {
            icon: "infantIcon",
            label: "Infants",
            name: "InfantCount",
            count: _ctx.$store.state.flightModule.infant_count,
            "onUpdate:InfantCount": _ctx.updateInfantCount,
            disabled: _ctx.handleDisableInfantPlusBtn,
            hint: "(0 to less then 2)",
            "test-id": ""
          }, null, 8, ["count", "onUpdate:InfantCount", "disabled"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }))
}