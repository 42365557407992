export enum PERMISSIONS {
  "CAN_VIEW_ALL_AGENTS" = "can_view_all_agents",
  "CAN_VIEW_AGENT_BALANCE" = "can_view_agent_balance",
  "CAN_DOES_ALL_OPERATIONS" = "can_does_all_operations",
  "CAN_VIEW_ALL_USERS" = "can_view_all_users",
  "CAN_ISSUE_TICKET" = "can_issue_ticket",
  "CAN_VIEW_SOA_ITEMS" = "can_view_soa_items",
  "CAN_REGISTER_USER" = "can_register_user",
  "CAN_VIEW_AGENTS_NEWS" = "can_view_agents_news",
  "CAN_VIEW_SEARCH_BOOK" = "can_view_search_book",
  "CAN_SEARCH_FLIGHTS" = "can_search_flights",
  "CAN_BOOK_FLIGHTS" = "can_book_flights",
  "CAN_FETCH_PNR" = "can_fetch_pnr",
  "CAN_ISSUE_FLIGHT_TICKET" = "can_issue_flight_ticket",
  "CAN_DOWNLOAD_SOA" = "can_download_soa",
  "CAN_EMAIL_SOA" = "can_email_SOA",
  "CAN_REQUEST_CREDIT_LIMIT" = "can_request_credit_limit",
  "CAN_CREATE_CARD_PAYMENT" = "can_create_card_payment",
  "CAN_CREATE_ONEBILL_PAYMENT" = "can_create_onebill_payment",
  "CAN_VIEW_ALL_PAYMENTS" = "can_view_all_payments",
  "CAN_VIEW_SELF_MADE_PAYMENTS" = "can_view_self_made_payments",
  "CAN_VIEW_ALL_FLIGHT_BOOKINGS" = "can_view_all_flight_bookings",
  "CAN_VIEW_SELF_MAKE_FLIGHT_BOOKINGS" = "can_view_self_make_flight_bookings",
  "CAN_VIEW_AGENT_PRICING" = "can_view_agent_pricing",
  "CAN_SEARCH_HOTEL" = "can_search_hotel",
  "CAN_BOOK_HOTEL" = "can_book_hotel",
  "CAN_ISSUE_HOTEL" = "can_issue_hotel",
  "CAN_VIEW_ALL_BOOKINGS_OF_HOTEL" = "can_view_all_bookings_of_hotel",
  "CAN_VIEW_ONLY_SELF_MADE_BOOKINGS_OF_HOTEL" = "can_view_only_self_made_bookings_of_hotel",
  "CAN_BOOK_TOUR" = "can_book_tour",
  "CAN_VIEW_ALL_BOOKINGS_OF_TOUR" = "can_view_all_bookings_of_tour",
  "CAN_VIEW_SELF_MADE_BOOKING_OF_TOUR" = "can_view_self_made_booking_of_tour",
  "CAN_BOOK_FIT" = "can_book_FIT",
  "CAN_VIEW_ALL_BOOKINGS_OF_FIT" = "can_view_all_bookings_of_FIT",
  "CAN_VIEW_ONLY_SELF_MADE_BOOKING_OF_FIT" = "can_view_only_self_made_booking_of_FIT",
  "CAN_CHANGE_ORGANIZATION_PREFERENCES" = "can_change_organization_preferences",
  "CAN_CHANGE_SUB-AGENT_PERMISSIONS" = "can_change_sub-agent_permissions",
  "CAN_CHANGE_EMPLOYEE_PERMISSIONS" = "can_change_employee_permissions",
  "CAN_LOGIN" = "can_login",
  "CAN_CREATE_PAYMENT" = "can_create_payment",
  "CAN_VIEW_SELF_MADE_FLIGHT_BOOKINGS" = "can_view_self_made_flight_bookings",
  "CAN_VIEW_ORGANIZATION" = "can_view_organization",
  "CAN_VIEW_ORGANIZATION_PREFERENCES" = "can_view_organization_preferences",
  "CAN_VIEW_ORGANIZATION_USERS" = "can_view_organization_users",
  "CAN_MANAGE_ORGANIZATION_SELF_PREFERENCES" = "can_manage_organization_self_preferences",
  "CAN_MANAGE_ORGANIZATION_USERS" = "can_manage_organization_users",
  "CAN_CHANGE_USER_PASSWORD" = "can_change_user_password",
  "CAN_VIEW_CREDIT_LIMIT" = "can_view_credit_limit",
  "CAN_VIEW_FIT" = "can_view_fit",
  "CAN_VIEW_GROUP_TOURS" = "can_view_group_tours",
  "CAN_VIEW_GROUP_FLIGHTS" = "can_view_group_flights",
}
