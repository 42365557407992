<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7003 24L10.667 15.9667L18.7003 7.93335L20.1337 9.36668L13.5337 15.9667L20.1337 22.5667L18.7003 24Z"
      fill="#49454F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MPreviousPageIcon",
});
</script>
