<template>
  <ag-modal persistent :is-open="isOpen" modal-width="40%">
    <template #body>
      <AgDiv test-id="" class="text-center">
        <AgIcon
          test-id=""
          name="flightTimerThumb"
          class="margin_bottom_20"
        ></AgIcon>
        <AgHeading title="Session Expired" variant="h3" test-id=""></AgHeading>
        <AgHeading
          title="Sorry, you ran out of time. Don’t worry you can book again"
          variant="p"
        ></AgHeading>
        <AGButton
          test-id=""
          @click="handleRefreshResults"
          variant="primary"
          type="button"
          >Search Again</AGButton
        >
      </AgDiv>
    </template>
  </ag-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TimeoutModal",
  events: ["toggle"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    handleRefreshResults: {
      type: Function,
      required: true,
    },
  },
});
</script>
