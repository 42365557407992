export enum PASSENGER_TYPE {
  ADULT = "ADT",
  CHILD = "CNN",
  INFANT = "INF",
}
export enum HOTEL_PASSENGER_TYPE {
  ADULT = "Adult",
  CHILD = "Child",
  INFANT = "Infant",
}
