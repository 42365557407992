<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5002 24L11.0669 22.5667L17.6669 15.9667L11.0669 9.36668L12.5002 7.93335L20.5336 15.9667L12.5002 24Z"
      fill="#49454F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MNextPageIcon",
});
</script>
