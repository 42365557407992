import * as yup from "yup";
import { SUPPLIER_NAMES } from "../enums/supplier_names";

export const fetchPNRValidation = yup.object().shape({
  pnr: yup.string().required("PNR is required"),
  comments: yup.string().optional(),
  airline: yup.string().nullable().required("Airline is required"),
  airline_code: yup
    .string()
    .nullable()
    .optional()
    .test("required", "Sabre Airline Required", (value, ctx) => {
      if (ctx.parent.airline === SUPPLIER_NAMES.SABRE) return value !== null;
      return true;
    }),
});
