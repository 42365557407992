import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgTextFieldDropDownItem = _resolveComponent("AgTextFieldDropDownItem")!
  const _component_AgAutoComplete = _resolveComponent("AgAutoComplete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      title: _ctx.localLabel,
      variant: "label"
    }, null, 8, ["title"]),
    _createVNode(_component_AgAutoComplete, {
      modelValue: _ctx.searchValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        _ctx.onUpdateModelValue
      ],
      icon: "calendar",
      items: _ctx.formattedAirports,
      "item-label": "label",
      onKeyup: _ctx.onChangeSearchField,
      "is-loading": _ctx.$store.getters.isAirportFetching,
      class: "flightField",
      value: _ctx.value,
      error: _ctx.error,
      "error-message": _ctx.errorMessage
    }, {
      dropdownItem: _withCtx(({ itemProps, itemData }) => [
        _createVNode(_component_AgTextFieldDropDownItem, _normalizeProps(_guardReactiveProps(itemProps)), {
          default: _withCtx(() => [
            _createVNode(_component_AgDiv, { class: "flightDropdownWrap" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgIcon, {
                  class: "flight_icon_wrap",
                  "test-id": "",
                  name: "flightGreenIcon"
                }),
                _createVNode(_component_AgDiv, { class: "airport_name" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(itemData.city) + ", " + _toDisplayString(itemData.country) + " ", 1),
                    _createVNode(_component_AgDiv, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(itemData.airport), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_AgDiv, { class: "airport_code" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(itemData.iata_code), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1040)
      ]),
      _: 1
    }, 8, ["modelValue", "items", "onKeyup", "is-loading", "onUpdate:modelValue", "value", "error", "error-message"])
  ], 64))
}