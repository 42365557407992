export const ANALYTICS_PREFIX_INFRA = "ag-infra_";
export const ANALYTICS_PREFIX_FLIGHT = "ag-flight_";
export const ANALYTICS_PREFIX_HOTEL = "ag-hotel_";
export const PREFIX_ROOT = "";

export const PAGE = {
  LOGIN: "Login",
  DASHBOARD: "Dashboard",
  SOA: "SOA",
  MY_BOOKINGS: "My Bookings",
  BOOKING_DETAILS: "Booking Details",
  ISSUE_TICKET: "Issue Ticket",
  AGENT_PRICING: "Agent Pricing",
  AGENT_LIST: "Agent List",
  CREATE_USER: "Create User",
  UPDATE_PROFILE: "Update Profile",
  CHANGE_PASSWORD: "Change Password",
  AGENT_NEWS: "Agent News",
  BUS: "Bus",
  OTP_MANAGEMENT: "OTP Management",
  HOTEL: "Hotel",
  CREDIT_LIMIT_MANAGEMENT: "Credit Limit Management",
  CREATE_CREDIT_LIMIT_REQUEST: "Create Credit Limit Request",
  AGENT_CREDIT_LIMIT_REQUESTS: "Agent Credit Limit Requests",
  CREDIT_LIMIT_REQUEST_APPROVAL: "Credit Limit Request Approval",
  HOTEL_SEARCH: "Hotel Search",
  HOTEL_SEARCH_RESULT: "Hotel Search Result",
  HOTEL_PROPERTY_DETEAIL: "Hotel Property Detail",
  HOTEL_PROPERTY_BOOKING_FORM: "Hotel Property Booking Form",
  HOTEL_BOOKING_DETAIL_PAGE: "Hotel Booking Detail Page",
  FLIGHT_SEARCH: "Flight Search",
  FLIGHT_SEARCH_RESULT: "Flight Search Result",
  FLIGHT_CONFIRM_BOOKING: "Flight Confirm Booking",
  FLIGHT_BOOKING_DETAILS: "Flight Booking Details",
  QUOTATION_DOWNLOAD: "Quotations Downloaded",
  QUOTATION_DELETED: "Quotation Deleted",
  QUOTATION_ADDED: "Quotation Added",
  QUOTATION_EDITED: "Quotation Edited",
};

export const ANALYTICS_COMMON_EVENTS = {
  VIEW_PAGE: "view-page-",
  SIDEBAR_ITEM: "sidebar-item-clicked",
  HEADER_ITEM_SIDEBAR_TOGGLE: "header-item-clicked-sidebar-toggle",
  HEADER_ITEM_DROPDOWN: "header-item-clicked-ddoptions",
  HEADER_ITEM_FINANCIAL_STATUS: "header-item-clicked-financial-status",
  HEADER_ITEM_FINANCIAL_BREAKDOWN: "header-item-clicked-financial-breakdown",
  WIDGET_CLICKED: "widget-clicked",
};
