<template>
  <AGAppContainer class="default-page-color">
    <a-g-full-width-page>
      <template v-slot:toolbar>
        <Header :title="title" />
      </template>

      <template v-slot:side-menu>
        <SideMenu />
      </template>
      <template v-slot:content-area>
        <AGContainer>
          <router-view />
          <ag-div
            class="fab dontAddThis"
            @click="onClickWidgetTrack('whatsapp')"
          >
            <a
              href="https://wa.me/923111145623"
              target="_blank"
              style="text-decoration: none; font-size: 50px; color: white"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </ag-div>
        </AGContainer>
      </template>
    </a-g-full-width-page>
  </AGAppContainer>
  <AgPopupAlert />
</template>

<script>
import Header from "@/ag-portal-common/components/Header.vue";
import SideMenu from "@/ag-portal-common/components/SideMenu.vue";
import AgPopupAlert from "@/ag-portal-common/components/AgPopupAlert.vue";
import analyticsService from "@/ag-portal-common/services/local_analytics.service";
import { ANALYTICS_COMMON_EVENTS } from "@/ag-portal-common/constants/analytics";

export default {
  name: "HomeLayout",
  props: {
    title: {
      type: String,
    },
  },
  components: {
    AgPopupAlert,
    SideMenu,
    Header,
  },
  methods: {
    onClickWidgetTrack(name) {
      const payload = {
        "item-name": name,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.WIDGET_CLICKED,
        payload
      );
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css";

.fab {
  position: fixed;
  bottom: 20px;
  right: 25px;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #10b981;
  z-index: 1;
}

.fab i {
  font-size: 36px;
  color: white;
  z-index: 2;
}
@media print {
  .dontAddThis {
    display: none;
  }
}
</style>
