export const FLIGHT_ANALYTICS_EVENTS = {
  AG_FLIGHT_SEARCH_APPLIED: "search_applied",
  AG_FLIGHT_FILTER_APPLIED: "filter_applied",
  AG_FLIGHT_FARE_CALCULATOR_APPLIED: "fare_calculator_applied",
  AG_FLIGHT_DETAIL_CARD_OPENED: "detail_card_opened",
  AG_FLIGHT_RBD_BTN_CLICKED: "rbd_btn_clicked",
  AG_FLIGHT_RBD_SELECTED: "rbd_btn_selected",
  AG_FLIGHT_FARE_OPTION_CONFIRMED: "fare_option_confirmed",
  AG_FLIGHT_BOOKING_CONFIRMED: "fare_booking_confirmed",
  AG_FLIGHT_BOOKING_ISSUANCE: "booking_issuance",
  AG_AIRLINE_TICKET_DIALOG_OPENED: "ag_airline_ticket_dialog_open",
  AG_AIRLINE_TICKET_SENT_VIA_DIALOG: "ag_airline_ticket_sent_via_dialog",
  AG_FLIGHT_TICKET_DOWNLOAD: "ag_flight_ticket_download",
  AG_FLIGHT_QUICK_SEARCH_CHIP_CLICK: "quick_search_chip_click",
  AG_FLIGHT_CHANGE_SEARCH_DIALOG_OPENED: "change_search_dialog_opened",
  AG_QUICK_DATE_SEARCH_CHIP: "quick_date_search_chip",
  ADD_TO_QUOTE: "Add-to-quote",
  PASSENGER_DROPDOWN_SELECT: "passenger-dropdown-select",
};

export const FLIGHTS_ANALYTICS_COMMON_EVENTS = {
  BOOKING_SEARCH: "flight-booking-search-clicked",
  BOOKING_SEARCH_APPLY: "flight-booking-search-applied",
  BOOKING_CONTINUE: "flight-booking-continue-clicked",
  BOOKING_CONFIRMED: "flight-booking-confirmed-clicked",
  BOOKING_CONFIRMED_VIA_RETURN_PAIRS:
    "flight-booking-confirmed-via-pairs-clicked",
  BOOK_FARE: "flight-book-fare-clicked",
  BOOK_FARE_EXPAND: "flight-book-fare-expanded",
  FARE_CALCULATOR: "flight-fare-calculator-clicked",
  FARE_CALCULATOR_ACTION: "flight-fare-calculator-action-clicked",
  ADD_TO_QUOTE: "Add-to-quote",
  PAIR_VIEW: "pair-view",
};
