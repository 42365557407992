import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgTicketRefundableType = _resolveComponent("AgTicketRefundableType")!
  const _component_AgStaticTable = _resolveComponent("AgStaticTable")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_AgAlertBox = _resolveComponent("AgAlertBox")!
  const _component_AgDiv = _resolveComponent("AgDiv")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_window_item, {
      style: {"width":"100%","":"110px"},
      value: "f_info_tab_2",
      transition: "fade",
      "reverse-transition": "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_AgTicketRefundableType, {
                origin: _ctx.origin,
                destination: _ctx.destination,
                "test-id": "",
                "is-refundable": _ctx.isRefundable
              }, null, 8, ["origin", "destination", "is-refundable"]),
              _createVNode(_component_AgStaticTable, {
                "test-id": "",
                columns: _ctx.cancellation?.headings,
                data: _ctx.cancellation?.data
              }, null, 8, ["columns", "data"])
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_v_window_item, {
      style: {"width":"100%","":"110px"},
      value: "f_info_tab_3",
      transition: "fade",
      "reverse-transition": "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (_openBlock(), _createBlock(_component_AgStaticTable, {
              key: 1,
              "test-id": "",
              columns: _ctx.dateChange?.headings,
              data: _ctx.dateChange?.data
            }, null, 8, ["columns", "data"]))
      ]),
      _: 1
    }),
    _createVNode(_component_v_window_item, {
      style: {"width":"100%","":"110px"},
      value: "f_info_tab_4",
      transition: "fade",
      "reverse-transition": "fade"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AgStaticTable, {
                "test-id": "",
                columns: _ctx.baggage?.headings,
                data: _ctx.baggage?.data
              }, null, 8, ["columns", "data"]),
              _createVNode(_component_AgDiv, {
                "test-id": "",
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgAlertBox, {
                    text: "Baggage allowance policy strictly enforced.",
                    color: "#10B981",
                    type: "warning",
                    "test-id": ""
                  })
                ]),
                _: 1
              })
            ]))
      ]),
      _: 1
    })
  ], 64))
}