<template>
  <AGSideMenu
    :logoURL="urlLogo"
    :items="filteredSideMenuOptions"
    style="margin: 0px; padding-bottom: 0px"
  >
    <template v-for="(item, index) in filteredSideMenuOptions" :key="index">
      <AGSideMenuItem
        :icon="item.icon"
        :name="item.name"
        :testId="
          genTestId(ELEMENT_TYPES.BUTTON, `side-menu-${item.name}-${index}`)
        "
        @click="toggleSubMenu(item.index - 1)"
        :class="{
          pulse: hasPulse(item.subMenuItems || []),
          active: openSubMenuIndex === index, // Apply the active class when it's the active item
        }"
      />
      <ag-div
        v-if="
          openSubMenuIndex === index &&
          item.subMenuItems &&
          item.subMenuItems.length > 0
        "
        class="submenu"
      >
        <AGSideMenuItem
          style="
            padding-left: 25px;
            padding-bottom: 0px;
            padding-top: 0px;
            padding-right: 0px;
            margin: 0px;
          "
          v-for="(subItem, subIndex) in item.subMenuItems"
          :key="subIndex"
          :icon="subItem.icon"
          :name="subItem.name"
          :testId="
            genTestId(
              ELEMENT_TYPES.BUTTON,
              `side-menu-${subItem.name}-${subIndex}`
            )
          "
          @onClickMenuItem="onClickMenuItemHandler(subItem, $event)"
          :class="{
            active: $route.path === subItem.path,
            pulse: subItem.hasPulse,
          }"
        />
      </ag-div>
    </template>

    <AGButton
      v-a-g-toggle:class.on-id.main_container="'menu_collapse'"
      variant="link"
      type="button"
      data-toggle-class="active"
      class="menu_toggle_sidebar_icon"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
          fill="#111827"
        />
      </svg>
    </AGButton>
  </AGSideMenu>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import {
  genTestId,
  isRoleAllowed,
  isUserPermitted,
  parseLocalStorageData,
} from "@/ag-portal-common/utils/helpers";
import analyticsService from "@/ag-portal-common/services/local_analytics.service";
import { ANALYTICS_COMMON_EVENTS } from "@/ag-portal-common/constants/analytics";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";

import { IOrganizationPreference } from "../interfaces/organization.interface";
import { ISideMenuItem, SideMenuItem } from "../interfaces/sidemenu.interface";
import { IObject } from "../interfaces/object.interface";
import { IUserV2 } from "../interfaces/user.interface";
import { PERMISSIONS } from "../enums/PERMISSIONS";

export default defineComponent({
  name: "SideMenu",
  data(): {
    ELEMENT_TYPES: IObject;
    selectedMenuItemIndex: number;
    selectedSubMenuItemIndex: number;
    openSubMenuIndex: number;
    sideMenuOptions: ISideMenuItem[];
  } {
    return {
      ELEMENT_TYPES,
      selectedMenuItemIndex: 1,
      selectedSubMenuItemIndex: 1,
      openSubMenuIndex: 1,
      sideMenuOptions: [
        {
          name: "Dashboard",
          path: PATH.DASHBOARD,
          icon: "dashboard",
          roles: ROLES_AND_PERMISSION_ON_ROUTES.DASHBOARD.ROLES,
          permissions: ROLES_AND_PERMISSION_ON_ROUTES.DASHBOARD.PERMISSIONS,
          index: 1,
          individual: true,
          subMenuItems: [],
        },
        {
          name: "Sell",
          index: 2,
          icon: "issueTicket",
          subMenuItems: [
            {
              name: "Flights",
              path: PATH.FLIGHTS,
              icon: "flightIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
              index: 21,
            },
            {
              name: "Hotels",
              path: PATH.HOTELS,
              icon: "hotelIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.HOTELS.PERMISSIONS,
              index: 22,
            },
            {
              name: "Group Flights",
              path: PATH.FDG_FLIGHTS,
              icon: "flightIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.FDG_FLIGHTS.PERMISSIONS,
              index: 23,
            },
            {
              name: "Umrah / Ziarat",
              path: PATH.FDG_UMRAH,
              icon: "umrah",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.FDG_UMRAH.PERMISSIONS,
              index: 24,
            },
            {
              name: "My Bookings",
              path: PATH.MY_BOOKINGS,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.MY_BOOKINGS.PERMISSIONS,
              index: 25,
            },
            {
              name: "Issue Ticket",
              path: PATH.FLIGHT_ISSUANCE,
              icon: "issueTicket",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.PERMISSIONS,
              index: 26,
            },
            {
              name: "Fare Management",
              path: PATH.FARE_MANAGEMENT,
              icon: "paymentManagement",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.FARE_MANAGEMENT.PERMISSIONS,
              index: 27,
            },
            {
              name: "Sabre Segment",
              path: PATH.SABRE_SEGMENT,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.SABRE_SEGMENT.PERMISSIONS,
              index: 28,
            },
            {
              name: "Issuance Logs",
              path: PATH.ISSUANCE_LOGS_WITHOUT_PNR,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.ISSUANCE_LOGS.PERMISSIONS,
              index: 28,
            },
            {
              name: "Manage Quotations",
              path: PATH.MANAGE_QUOTATIONS,
              icon: "menuQuotations",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.MANAGE_QUOTATIONS.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.MANAGE_QUOTATIONS.PERMISSIONS,
              index: 29,
            },
          ],
        },
        {
          name: "Organization Management",
          index: 3,
          icon: "organizations",
          subMenuItems: [
            {
              name: "Agent Management",
              path: PATH.AGENT_MANAGEMENT,
              icon: "agents",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.AGENT_MANAGEMENT.PERMISSIONS,
              index: 31,
            },
            {
              name: "Organizations",
              path: PATH.ORGANIZATION,
              icon: "organizations",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION.PERMISSIONS,
              index: 32,
            },
            {
              name: "Details",
              path: `${PATH.ORGANIZATION}/${
                this.organizationPreference()?.organization_id
              }`,
              icon: "organizations",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_DETAIL.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_DETAIL.PERMISSIONS,
              index: 33,
            },
            {
              name: "Agents",
              path: PATH.AGENT,
              icon: "agents",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.AGENT_LIST.PERMISSIONS,
              index: 34,
            },
            {
              name: "Preference",
              path: PATH.PREFERENCE,
              icon: "preferenceIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.PREFERENCE.PERMISSIONS,
              index: 35,
            },
            {
              name: "Website Management",
              path: PATH.WEBSITE_MANAGEMENT,
              icon: "menuWebsite",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.WEBSITE_MANAGEMENT.PERMISSIONS,
              index: 36,
            },
            {
              name: "Passenger Management",
              path: PATH.PASSENGER_MANAGEMENT,
              icon: "menuPassengers",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.PASSENGER_MANAGEMENT.PERMISSIONS,
              index: 37,
            },
            {
              name: "Sector Snapshot",
              path: PATH.SECTOR_SNAPSHOT,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.SECTOR_SNAPSHOT.PERMISSIONS,
              index: 38,
            },
            {
              name: "Organization Snapshot",
              path: PATH.ORGANIZATION_SNAPSHOT,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.ORGANIZATION_SNAPSHOT
                  .PERMISSIONS,
              index: 39,
            },
            {
              name: "Whatsapp Management",
              path: PATH.WHATSAPP_MANAGEMENT,
              icon: "whatsappIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.WHATSAPP_MANAGEMENT.PERMISSIONS,
              index: 40,
            },
          ],
        },
        {
          name: "Finance",
          icon: "paymentManagement",
          index: 4,
          subMenuItems: [
            {
              name: "Payment Management",
              path: PATH.PAYMENTS,
              icon: "paymentManagement",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.PAYMENTS.PERMISSIONS,
              index: 41,
            },
            {
              name: "Credit Limit Management",
              path: PATH.CREDIT_LIMIT_MANAGEMENT,
              icon: "creditLimit",
              roles:
                ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.CREDIT_LIMIT_MANAGEMENT
                  .PERMISSIONS,
              index: 42,
            },
            {
              name: "SOA",
              path: PATH.SOA,
              icon: "soa",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.SOA.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.SOA.PERMISSIONS,
              index: 43,
            },
            {
              name: "AG Pricing",
              path: PATH.AG_PRICING,
              icon: "agentPricing",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.AG_PRICING.PERMISSIONS,
              index: 44,
              hasPulse: true,
            },
            {
              name: "Currency Conversion",
              path: PATH.CURRENCY_CONVERSION,
              icon: "paymentManagement",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.CURRENCY_CONVERSION.PERMISSIONS,
              index: 45,
            },
            {
              name: "Sales Snapshot",
              path: PATH.SALES_SNAPSHOT,
              icon: "mybookings",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.SALES_SNAPSHOT.PERMISSIONS,
              index: 46,
            },
          ],
        },
        {
          name: "More",
          index: 5,
          icon: "agentNews",
          subMenuItems: [
            {
              name: "Aeroglobe Tutorials",
              path: PATH.AEROGLOBE_TUTORIALS,
              externalLink: true,
              icon: "tutorials",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.AG_TUTORIALS.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.AG_TUTORIALS.PERMISSIONS,
              index: 51,
            },
            {
              name: "News",
              path: PATH.NEWS,
              icon: "menuNews",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.NEWS.ROLES,
              permissions: ROLES_AND_PERMISSION_ON_ROUTES.NEWS.PERMISSIONS,
              index: 52,
            },
            {
              name: "Profile",
              path: PATH.USER_PROFILE,
              icon: "adultIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.USERPROFILE.PERMISSIONS,
              index: 53,
            },
            {
              name: "My Team",
              path: PATH.MY_TEAM,
              icon: "adultIcon",
              roles: ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.ROLES,
              permissions:
                ROLES_AND_PERMISSION_ON_ROUTES.SALES_MY_TEAM.PERMISSIONS,
              index: 54,
            },
          ],
        },
      ],
    };
  },
  computed: {
    filteredSideMenuOptions(): ISideMenuItem[] {
      const userRole = this.user()?.role_unique_id as USER_ROLES;
      const userPermissions = this.permissions() || [];
      const paymentManagementOff = [
        "Credit Limit Management",
        "Payment Management",
      ];

      const filterMenus = (menus: ISideMenuItem[]): ISideMenuItem[] => {
        return menus
          .map((menu: ISideMenuItem) => {
            const isAgentUser = userRole === USER_ROLES.AGENT;

            const filteredMenu: ISideMenuItem = {
              ...menu,
              subMenuItems: [],
            };

            if (menu.individual) {
              const isRolesAllowed = isRoleAllowed(userRole, menu.roles || []);
              const hasPermissions = this.hasAllowedPermissions(
                isAgentUser,
                menu.permissions || [],
                userPermissions
              );
              if (isRolesAllowed && hasPermissions) {
                return filteredMenu;
              }
            } else {
              const subMenuItems = menu.subMenuItems.filter(
                (item: SideMenuItem) => {
                  const isRolesAllowed = isRoleAllowed(userRole, item.roles);
                  const hasPermissions = this.hasAllowedPermissions(
                    isAgentUser,
                    item.permissions,
                    userPermissions
                  );

                  return isRolesAllowed && hasPermissions;
                }
              );
              filteredMenu.subMenuItems = subMenuItems;

              // Additional condition for Finance menu and specific sector
              if (
                menu.name === "Finance" &&
                localStorage.getItem("sector") === "Aeroglobe - Dubai"
              ) {
                filteredMenu.subMenuItems = filteredMenu.subMenuItems.filter(
                  (submenu) => !paymentManagementOff.includes(submenu.name)
                );
              }
              return filteredMenu;
            }
            return null;
          })
          .filter(Boolean) as ISideMenuItem[];
      };

      const menuItems = filterMenus(this.sideMenuOptions);
      const filteredMenuItems = menuItems.filter((item) => {
        if (item.individual) {
          return true;
        } else {
          return item.subMenuItems.length > 0;
        }
      });
      return filteredMenuItems;
    },

    urlLogo() {
      const organizationPreference = parseLocalStorageData(
        STORAGE_KEYS.PREFERENCES
      );
      return organizationPreference?.logo;
    },
  },
  methods: {
    genTestId,
    toggleSubMenu(index: number) {
      if (index == 0) {
        this.$router.push(PATH.DASHBOARD); // Navigate to the dashboard route
      }

      if (this.openSubMenuIndex === index) {
        this.openSubMenuIndex = -1; // Close the sub-menu if it's already open
      } else {
        this.openSubMenuIndex = index; // Open the sub-menu
      }
    },
    closeMenu() {
      let items = document.getElementsByClassName("menu_initialize");
      let sideMenuElement = items?.length ? items[0] : null;
      if (sideMenuElement) {
        sideMenuElement.classList.remove("menu_collapse");
      }
    },
    onClickItemTrack(name: string) {
      const payload = {
        "item-name": name,
      };
      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.SIDEBAR_ITEM,
        payload
      );
    },
    onClickMenuItemHandler(item: SideMenuItem, $event: MouseEvent) {
      if (item.externalLink || $event.ctrlKey || $event.metaKey) {
        window.open(item.path);
      } else {
        this.$router.push(item.path);
      }

      this.onClickItemTrack(item.name);

      // Update the selectedMenuItemIndex
      this.selectedSubMenuItemIndex = item.index;
      this.closeMenu();
    },
    hasPulse(subMenuItems: SideMenuItem[]): boolean {
      return subMenuItems.some((x) => x.hasPulse);
    },
    hasAllowedPermissions(
      isAgentUser: boolean,
      permissions: Array<PERMISSIONS>,
      userPermissions: Array<PERMISSIONS>
    ): boolean {
      return isAgentUser ? isUserPermitted(permissions, userPermissions) : true;
    },
  },
  setup() {
    const permissions = inject(
      AUTH_CONTEXT_KEYS.permissions
    ) as () => Array<PERMISSIONS>;
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    ) as () => IOrganizationPreference;
    let user = inject(AUTH_CONTEXT_KEYS.user) as () => IUserV2 | null;
    return {
      permissions,
      user,
      organizationPreference,
    };
  },
  mounted() {
    const path = this.$route.path;
    if (path !== "/") {
      const itemIndex = this.sideMenuOptions.findIndex((option) => {
        if (option.individual) {
          return option.path === path;
        } else {
          return option.subMenuItems.some((subMenu) => subMenu.path === path);
        }
      });
      this.openSubMenuIndex = itemIndex;
    }
  },
});
</script>

<style scoped>
.pulse {
  border-radius: 10px;
  animation: pulse 1.5s infinite;
}

.active {
  color: var(--green-color) !important;
  border-radius: 10px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
    box-shadow: 0 0 0 1px var(--green-color) !important;
  }
}
</style>
