<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_21_45"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.596619" y="0.790527" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_21_45)">
      <path
        d="M19.5966 15.7905C18.7466 15.7905 18.0341 15.503 17.4591 14.928C16.8841 14.353 16.5966 13.6405 16.5966 12.7905C16.5966 11.9405 16.8841 11.228 17.4591 10.653C18.0341 10.078 18.7466 9.79053 19.5966 9.79053C20.4466 9.79053 21.1591 10.078 21.7341 10.653C22.3091 11.228 22.5966 11.9405 22.5966 12.7905C22.5966 13.6405 22.3091 14.353 21.7341 14.928C21.1591 15.503 20.4466 15.7905 19.5966 15.7905ZM9.59662 17.7905L8.19662 16.3655L10.7716 13.7905H2.59662V11.7905H10.7716L8.19662 9.19053L9.59662 7.79053L14.5966 12.7905L9.59662 17.7905Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MFlightTimelineIcon",
});
</script>
