import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    persistent: "",
    "is-open": _ctx.isOpen,
    "modal-width": "40%"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_AgHeading, {
        title: "Cancel Booking",
        variant: "h3",
        "test-id": ""
      }),
      _createVNode(_component_AgDiv, null, {
        default: _withCtx(() => [
          _createTextVNode("Are you sure about cancel booking?")
        ]),
        _: 1
      }),
      _createVNode(_component_AgDiv, { style: {"display":"flex","float":"right"} }, {
        default: _withCtx(() => [
          _createVNode(_component_AGButton, {
            style: {"margin-right":"10px"},
            "test-id": "",
            onClick: _ctx.handleReject,
            variant: "danger",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("No")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_AGButton, {
            "test-id": "",
            onClick: _ctx.handleAccpet,
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Yes")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}