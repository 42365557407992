export enum ELEMENT_TYPES {
  BUTTON = "btn",
  LINK = "link",
  TEXT_FIELD = "text-field",
  FILE_UPLOAD_FIELD = "file-upload-field",
  SELECT_FIELD = "select-field",
  CALENDAR_FIELD = "calendar-field",
  CHECK_BOX = "check-box",
  RADIO_BUTTON = "radio-btn",
  ACCORDION = "accordion",
  LIST_ITEM = "lst-itm",
  SNACK_BAR = "snack-bar",
  TEXT = "text",
}
