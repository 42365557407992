import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgFlightTerminalInfo = _resolveComponent("AgFlightTerminalInfo")!
  const _component_AgHeading = _resolveComponent("AgHeading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AgFlightTerminalInfo, {
      "origin-city": _ctx.segment.origin.city,
      "origin-airport-name": _ctx.segment.origin.airport,
      "origin-time": _ctx.formatTime(_ctx.segment.departure_datetime),
      duration: _ctx.legDuration(_ctx.segment.duration_minutes),
      "destination-city": _ctx.segment.destination.city,
      "destination-airport-name": _ctx.segment.destination.airport,
      "destination-time": _ctx.formatTime(_ctx.segment.arrival_datetime),
      "airline-logo": _ctx.segment.operating_airline.logo,
      "airline-code": _ctx.renderFlightNumber(_ctx.segment.flight_number),
      "airline-name": _ctx.segment.operating_airline.name,
      "test-id": ""
    }, null, 8, ["origin-city", "origin-airport-name", "origin-time", "duration", "destination-city", "destination-airport-name", "destination-time", "airline-logo", "airline-code", "airline-name"]),
    (_ctx.showLayOff)
      ? (_openBlock(), _createBlock(_component_AgHeading, {
          key: 0,
          class: "flight_info_green_box highlighted",
          title: _ctx.renderLayoffTime,
          variant: "h4",
          "test-id": ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 64))
}