<template>
  <ag-row>
    <!-- Origin and Destination columns -->
    <ag-column xs="12" sm="12" md="6">
      <AgDiv class="combine_flight">
        <ag-row test-id="">
          <ag-column xs="12" sm="12" md="6" cols="12">
            <AirportSearchAutoComplete
              @update:origin="searchOriginAirportQuery"
              @onSelect:origin="changeOrigin"
              label="From"
              name="origin"
              :items="renderOriginPlaces"
              :value="$store.state.flightModule.origin"
              :error="errors?.origin"
            />
          </ag-column>
          <AGButton
            class="compare_icon"
            style="background-color: var(--green-color) !important"
            @click="swapFields"
            :class="{ flipped: true }"
            variant="link"
          >
            <ag-icon name="compareIcon" />
          </AGButton>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <AirportSearchAutoComplete
              @update:destination="searchDestinationAirportQuery"
              @onSelect:destination="changeDestination"
              label="To"
              name="destination"
              :items="renderDestinationPlaces"
              :value="$store.state.flightModule.destination"
              :error="errors?.destination"
            />
          </ag-column>
        </ag-row>
      </AgDiv>
    </ag-column>

    <!-- Departure Date and Return Date columns -->
    <ag-column xs="12" sm="12" md="6" cols="12">
      <AgDiv>
        <ag-row>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <ag-calendar
              label="Departure Date"
              v-model="$store.state.flightModule.departure_date"
              :min-date="new Date()"
              :date="$store.state.flightModule.departure_date"
              @update:departureDate="changeDepartureDate"
              calendar-name="departureDate"
              IconName="departureFlightIcon"
            />
          </ag-column>
          <ag-column xs="12" sm="12" md="6" cols="12">
            <ag-calendar
              label="Return Date"
              v-model="$store.state.flightModule.return_date"
              :date="$store.state.flightModule.return_date"
              :min-date="$store.state.flightModule.departure_date"
              :disabled="disableReturnDatePicker"
              @update:returnDate="changeReturnDate"
              calendar-name="returnDate"
              IconName="departureFlightIcon"
            />
          </ag-column>
        </ag-row>
      </AgDiv>
    </ag-column>

    <!-- Search Flights button -->
    <ag-column xs="12" sm="12" md="12">
      <AgDiv test-id="" class="text-center">
        <a-g-button
          @click="onFlightSearch"
          :isLoading="$store.getters.isFlightsLoading"
          >Search Flights</a-g-button
        >
      </AgDiv>
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { debounce } from "lodash";

import AirportSearchAutoComplete from "./AirportSearchAutoComplete.vue";
import { ROUTE_TYPE } from "../../enums/route_type";
import { Place } from "../../types/Place";

export default defineComponent({
  name: "NormalFlightSearch",
  components: {
    AirportSearchAutoComplete,
  },
  props: {
    onFlightSearch: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      originAirportQuery: "",
      destinationAirportQuery: "",
    };
  },
  computed: {
    disableReturnDatePicker() {
      return this.$store.state.flightModule.route_type === ROUTE_TYPE.ONEWAY;
    },
    renderOriginPlaces() {
      const airports = this.$store.getters.airports as Array<Place>;
      const selectedDestination = this.$store.state.flightModule
        .destination as Place;
      if (selectedDestination) {
        return airports.filter(
          (x) => x.iata_code !== selectedDestination.iata_code
        );
      }
      return airports;
    },
    renderDestinationPlaces() {
      const airports = this.$store.getters.airports as Array<Place>;
      const selectedOrigin = this.$store.state.flightModule.origin as Place;
      if (selectedOrigin) {
        return airports.filter((x) => x.iata_code !== selectedOrigin.iata_code);
      }
      return airports;
    },
    ...mapState(["flightModule"]),
  },
  watch: {
    originAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchAirports(value);
      }, 500),
      immediate: true,
    },
    destinationAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.searchAirports(value);
      }, 500),
      immediate: true,
    },
  },
  methods: {
    changeDepartureDate(newDate: Date) {
      this.$store.commit("saveDepartureDate", newDate);
    },
    changeReturnDate(newDate: Date) {
      this.$store.commit("saveReturnDate", newDate);
    },
    changeOrigin(origin: Place) {
      this.$store.commit("saveOrigin", origin);
    },
    changeDestination(destination: Place) {
      this.$store.commit("saveDestination", destination);
    },
    searchOriginAirportQuery(value: string) {
      this.originAirportQuery = value;
    },
    searchDestinationAirportQuery(value: string) {
      this.destinationAirportQuery = value;
    },
    searchAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchAirports", value);
      }
    },
    swapFields() {
      const tempOrigin = this.$store.state.flightModule?.origin;
      const tempDestination = this.$store.state.flightModule?.destination;
      this.changeOrigin(tempDestination);
      this.changeDestination(tempOrigin);
    },
  },
});
</script>
