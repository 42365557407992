import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06c15ed9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-wrapper" }
const _hoisted_2 = { class: "m-table" }
const _hoisted_3 = { class: "m-pagination" }
const _hoisted_4 = { class: "rows-per-page" }
const _hoisted_5 = { class: "count" }
const _hoisted_6 = { class: "controls" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_typography = _resolveComponent("m-typography")!
  const _component_m_first_page_icon = _resolveComponent("m-first-page-icon")!
  const _component_m_previous_page_icon = _resolveComponent("m-previous-page-icon")!
  const _component_m_next_page_icon = _resolveComponent("m-next-page-icon")!
  const _component_m_last_page_icon = _resolveComponent("m-last-page-icon")!
  const _component_m_card = _resolveComponent("m-card")!

  return (_openBlock(), _createBlock(_component_m_card, { class: "m-flight-summary" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("table", _hoisted_2, [
          _createElementVNode("thead", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
              return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(header), 1))
            }), 128))
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedTravelers, (traveler, index1) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: index1,
                class: _normalizeClass(_ctx.travelers.length > 0 ? 'bordered' : '')
              }, [
                _createElementVNode("td", null, _toDisplayString(traveler.name), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.surname), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.type), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.birthdate), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.base_fare), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.surcharges), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.taxes), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.fees), 1),
                _createElementVNode("td", null, _toDisplayString(traveler.total_fare), 1)
              ], 2))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_m_typography, { type: "body" }, {
              default: _withCtx(() => [
                _createTextVNode("Rows per page")
              ]),
              _: 1
            }),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rowsPerPage) = $event)),
              class: "rows-count",
              type: "number",
              min: "1"
            }, null, 512), [
              [_vModelText, _ctx.rowsPerPage]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_m_typography, { type: "body" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.paginationRange), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "control-action first-page",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goToFirstPage && _ctx.goToFirstPage(...args))),
              disabled: _ctx.isFirstPage
            }, [
              _createVNode(_component_m_first_page_icon)
            ], 8, _hoisted_7),
            _createElementVNode("div", {
              class: "control-action previous",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousPage && _ctx.goToPreviousPage(...args))),
              disabled: _ctx.isFirstPage
            }, [
              _createVNode(_component_m_previous_page_icon)
            ], 8, _hoisted_8),
            _createElementVNode("div", {
              class: "control-action next",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goToNextPage && _ctx.goToNextPage(...args))),
              disabled: _ctx.isLastPage
            }, [
              _createVNode(_component_m_next_page_icon)
            ], 8, _hoisted_9),
            _createElementVNode("div", {
              class: "control-action last-page",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToLastPage && _ctx.goToLastPage(...args))),
              disabled: _ctx.isLastPage
            }, [
              _createVNode(_component_m_last_page_icon)
            ], 8, _hoisted_10)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}