import { IRestClient } from "@/ag-portal-common/interfaces/restClient.interface";
import { PATH } from "@/ag-portal-common/constants/path";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";
import storageService from "@/ag-portal-common/services/storage.service";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { API_ENDPOINTS } from "@/ag-portal-common/configs/apiEndpoints";
import loggerService from "@/ag-portal-common/services/logger.service";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";

class RestClientService implements IRestClient {
  private readonly axios: AxiosInstance;
  private storageService = storageService;
  private agResponse: IAGResponse<AxiosResponse> = {
    success: false,
  };
  private whiteListRoutes = [
    API_ENDPOINTS.LOGIN_ENDPOINT,
    API_ENDPOINTS.FORGOT_PASSWORD_ENDPOINT,
    API_ENDPOINTS.REFRESH_TOKEN_ENDPOINT,
  ];

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 180000,
    });
    this.axios.interceptors.request.use((req: InternalAxiosRequestConfig) => {
      const token = this.storageService.getItem(STORAGE_KEYS.ACCESS_TOKEN);
      if (token && req.headers) {
        req.headers["Authorization"] = `Bearer ${token}`;
      }
      return req;
    });
    this.axios.interceptors.response.use(
      (response: AxiosResponse): any => {
        return this.handleResponse(response);
      },
      (err: AxiosError) => {
        return this.handleError(err);
      }
    );
  }

  public async get(
    endpoint: string,
    params?: any,
    additionalConfig?: any
  ): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.get: INITIATED", {
      endpoint,
      params,
      additionalConfig,
    });
    additionalConfig ||= {};
    return this.axios.get(endpoint, { params, ...additionalConfig });
  }

  public async post(
    endpoint: string,
    body: any,
    additionalConfig?: any
  ): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.post: INITIATED", {
      endpoint,
      body,
      additionalConfig,
    });
    return this.axios.post(endpoint, body, additionalConfig);
  }

  public async put(endpoint: string, body?: any): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.put: INITIATED", {
      endpoint,
      body,
    });
    return this.axios.put(endpoint, body);
  }

  public async patch(
    endpoint: string,
    body: any,
    params?: any
  ): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.patch: INITIATED", {
      endpoint,
      body,
    });
    return this.axios.patch(endpoint, body, { params: params || {} });
  }

  public async getById(
    endpoint: string,
    id: string
  ): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.getById: INITIATED", {
      endpoint,
      id,
    });
    endpoint = `${endpoint}/${id}`;
    return this.axios.get(endpoint);
  }

  public async delete(endpoint: string, id: string): Promise<IAGResponse<any>> {
    loggerService.logInfo("RestClientService.delete: INITIATED", {
      endpoint,
      id,
    });
    endpoint = `${endpoint}/${id}`;
    return await this.axios.delete(endpoint);
  }

  private handleResponse(response: AxiosResponse) {
    loggerService.logInfo(
      `RestClientService.handleResponse: INITIATED ${JSON.stringify(response)}`
    );
    this.agResponse = {
      status: response.status,
      data: response?.data?.response || response?.data,
      message: response?.data?.message || "",
      success:
        typeof response?.data?.success === "boolean"
          ? response?.data?.success
          : true,
      error: null,
    };
    loggerService.logInfo(
      `RestClientService.handleResponse: ENDED ${JSON.stringify(
        this.agResponse
      )}`
    );
    return this.agResponse;
  }

  private async handleError(error: AxiosError) {
    const originalConfig: any = error.config;
    //Check if request is not a whitelist endpoint
    loggerService.logInfo(
      `RestClientService.handleError: INITIATED ${JSON.stringify(error)}`
    );
    if (
      !this.whiteListRoutes.includes(originalConfig.url) &&
      error?.response &&
      error?.response?.status === StatusCodes.UNAUTHORIZED
    ) {
      const refreshToken = this.storageService.getItem(
        STORAGE_KEYS.REFRESH_TOKEN
      );
      const accessToken = this.storageService.getItem(
        STORAGE_KEYS.ACCESS_TOKEN
      );
      if (originalConfig?._retry || !refreshToken || !accessToken) {
        window.location.replace(PATH.AG_FOUR_ZERO_ONE);
        // authBus.emit(AUTH_EVENTS.LOGOUT);
        this.agResponse.status = StatusCodes.UNAUTHORIZED;
      } else {
        originalConfig._retry = true;
        return this.refreshTokenCall(originalConfig);
      }
    } else {
      this.agResponse.status = StatusCodes.BAD_REQUEST;
    }
    const errorResponse: any = error?.response?.data;
    this.agResponse.success = false;
    this.agResponse.error = errorResponse?.message || "";
    loggerService.logInfo(
      `RestClientService.handleError: ENDED ${JSON.stringify(this.agResponse)}`
    );
    return Promise.resolve(this.agResponse);
  }

  private async refreshTokenCall(originalRequestConfig: AxiosRequestConfig) {
    try {
      loggerService.logInfo(
        "RestClientService.refreshTokenCall: INITIATED",
        originalRequestConfig
      );
      const refreshTokenApiResponse: IAGResponse<any> = await this.post(
        API_ENDPOINTS.REFRESH_TOKEN_ENDPOINT,
        {
          refresh: this.storageService.getItem(STORAGE_KEYS.REFRESH_TOKEN),
        }
      );
      const { access, refresh } = refreshTokenApiResponse.data;
      this.storageService.setItem(STORAGE_KEYS.ACCESS_TOKEN, access);
      this.storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh);
      return this.axios(originalRequestConfig);
    } catch (_error) {
      loggerService.logError(
        "RestClientService.refreshTokenCall: ERROR",
        _error
      );
      authBus.emit(AUTH_EVENTS.LOGOUT);
    }
  }
}

export default RestClientService;
