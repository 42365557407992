import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      variant: "h2",
      title: "Price Summary",
      class: "margin_bottom_10"
    }),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderAdultPriceSummaryTitle.label,
      price: _ctx.renderAdultPriceSummaryTitle.price
    }, null, 8, ["info", "price"]), [
      [_vShow, _ctx.showAdultBreakDown]
    ]),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderChildPriceSummaryTitle.label,
      price: _ctx.renderChildPriceSummaryTitle.price
    }, null, 8, ["info", "price"]), [
      [_vShow, _ctx.showChildBreakDown]
    ]),
    _withDirectives(_createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: _ctx.renderInfantPriceSummaryTitle.label,
      price: _ctx.renderInfantPriceSummaryTitle.price
    }, null, 8, ["info", "price"]), [
      [_vShow, _ctx.showInfantBreakDown]
    ]),
    _createVNode(_component_AgPriceSummary, {
      "test-id": "",
      info: "Price You Pay",
      price: _ctx.renderTotalGrossPrice,
      "total-price": "true"
    }, null, 8, ["price"]),
    _renderSlot(_ctx.$slots, "flightDetailsActionButtons")
  ], 64))
}