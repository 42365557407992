import * as yup from "yup";
import { differenceInYears, differenceInMonths } from "date-fns";
import { PASSENGER_TYPE } from "@/ag-flight-components/enums/passenger-type";
import { validateMobileNumber } from "@/ag-portal-common/utils/helpers";

export const bookingFormValidationSchema = yup.object().shape({
  mobile_number: yup
    .string()
    .nullable()
    .required("Phone number is required")
    .test("valid", "Phone number is not valid", (value, { parent }) => {
      return parent.isValid;
    }),
  isValid: yup.boolean().nullable().required(""),
  travelers: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().nullable().required("Title is required"),
        first_name: yup
          .string()
          .nullable()
          .min(2, "Please enter a valid First Name")
          .required("First Name is required"),
        last_name: yup
          .string()
          .nullable()
          .min(2, "Please enter a valid Last Name")
          .required("Last Name is required"),
        dob: yup
          .date()
          .nullable()
          .test(
            "required",
            "Adult age must be greater than or equal to 12yrs",
            (value, ctx) => {
              if (ctx.parent.passenger_type === PASSENGER_TYPE.ADULT) {
                return differenceInYears(new Date(), value as Date) >= 12;
              }
              return true;
            }
          )
          .test(
            "required",
            "Child age must be greater than 2 and less than or equal to 11yrs",
            (value, ctx) => {
              if (ctx.parent.passenger_type === PASSENGER_TYPE.CHILD) {
                const ageInYears = differenceInYears(new Date(), value as Date);
                return ageInYears >= 2 && ageInYears <= 11;
              }
              return true;
            }
          )
          .test(
            "required",
            "Infant age must be less than or equal to 23 months",
            (value, ctx) => {
              if (ctx.parent.passenger_type === PASSENGER_TYPE.INFANT) {
                const ageInMonths = differenceInMonths(
                  new Date(),
                  value as Date
                );
                return ageInMonths <= 23 && ageInMonths >= 0;
              }
              return true;
            }
          ),
        gender: yup.string().nullable().required("Gender is required"),
        nationality: yup
          .string()
          .nullable()
          .required("Nationality is required"),
        cnic: yup
          .string()
          .nullable()
          .test("required", "CNIC is required.", (value, ctx) => {
            if (!ctx.parent.is_international && !value) {
              return false;
            }
            return true;
          })
          .test(
            "len",
            "CNIC must be numeric and 13 characters long.",
            (val, ctx) => {
              if (!ctx.parent.is_international) {
                return (
                  val?.split(" ").join("").length === 13 && /[0-9]/.test(val)
                );
              }
              return true;
            }
          ),
        passport_number: yup
          .string()
          .nullable()
          .test("required", "Passport number is required.", (value, ctx) => {
            if (ctx.parent.is_international && !value) {
              return false;
            }
            return true;
          }),
        passport_expiry: yup
          .date()
          .nullable()
          .test("required", "Passport expiry is required.", (value, ctx) => {
            if (ctx.parent.is_international && !value) {
              return false;
            }
            return true;
          }),
      })
    )
    .min(1, "Minimum 1 traveler is required")
    .required("Minimum 1 traveler is required"),
});
