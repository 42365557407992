import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_STYLES } from "../enums/NOTIFICATION_STYLES";

class NotificationService implements INotification {
  public title?: string | undefined = "";
  public actionButtonText?: string | undefined = "";
  public action?: (() => void) | undefined;
  public notificationStyle?: NOTIFICATION_STYLES | undefined =
    NOTIFICATION_STYLES.SNACKBAR;
  public type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.SUCCESS;
  public description = "";

  public triggerNotification() {
    notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, {
      title: this?.title || "",
      type: this.type,
      description: this.description,
      action: this.action,
      actionButtonText: this.actionButtonText,
      notificationStyle: this.notificationStyle,
    } as INotification);
  }
}

export default new NotificationService();
