export const AUTH_CONTEXT_KEYS = {
  user: "user",
  enableIsAuthenticating: "enableIsAuthenticating",
  setPermissions: "setPermissions",
  setUser: "setUser",
  clearAuthInfo: "clearAuthInfo",
  setSettings: "setSettings",
  disableIsAuthenticating: "disableIsAuthenticating",
  updateOrganizationPreference: "updateOrganizationPreference",
  settings: "settings",
  permissions: "permissions",
  organization: "organization",
  organizationPreference: "organizationPreference",
  isUserSignedIn: "isUserSignedIn",
  isAuthenticating: "isAuthenticating",
};
