<template>
  <ag-heading variant="h2" title="Price Summary" class="margin_bottom_10" />
  <AgPriceSummary
    test-id=""
    v-show="showAdultBreakDown"
    :info="renderAdultPriceSummaryTitle.label"
    :price="renderAdultPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    v-show="showChildBreakDown"
    :info="renderChildPriceSummaryTitle.label"
    :price="renderChildPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    v-show="showInfantBreakDown"
    :info="renderInfantPriceSummaryTitle.label"
    :price="renderInfantPriceSummaryTitle.price"
  />
  <AgPriceSummary
    test-id=""
    info="Price You Pay"
    :price="renderTotalGrossPrice"
    total-price="true"
  />
  <slot name="flightDetailsActionButtons"> </slot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";
import { FlightDetailsType, FlightLeg } from "@/ag-flight-components/types";

export default defineComponent({
  name: "PriceSummary",
  methods: {
    formatCurrency(amount: number, currency_format: string) {
      return getCurrencyFormatter(currency_format).format(amount);
    },
  },
  computed: {
    showAdultBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.adult_count) > 0;
    },
    showChildBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.child_count) > 0;
    },
    showInfantBreakDown() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      return Number(flightDetails?.infant_count) > 0;
    },
    renderAdultPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.adult?.ag_total_amount
          .currency
      );
      return {
        label: `${airline} (adult) x ${flightDetails.adult_count}`,
        price,
      };
    },
    renderChildPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
            .value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.child?.ag_total_amount
          .currency
      );
      return {
        label: `${airline} (child) x ${flightDetails.child_count}`,
        price,
      };
    },
    renderInfantPriceSummaryTitle() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      const legs: FlightLeg[] = flightDetails?.flight_legs || [];
      const airline = legs[0]?.segment[0]?.marketing_airline?.name;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const price = this.formatCurrency(
        Number(
          flightDetails?.price?.pax_type_price_breakdown?.infant
            ?.ag_total_amount.value
        ),
        flightDetails?.price?.pax_type_price_breakdown?.infant?.ag_total_amount
          .currency
      );
      return {
        label: `${airline} (infant) x ${flightDetails.infant_count}`,
        price,
      };
    },
    renderTotalGrossPrice() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.formatCurrency(
        Number(flightDetails?.ag_total_amount?.value),
        flightDetails?.ag_total_amount?.currency
      );
    },
    renderTotalGrossPrice2() {
      const flightDetails: FlightDetailsType =
        this.$store.getters.flightDetails || {};
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.formatCurrency(
        Number(flightDetails?.price?.gross_fare.value),
        flightDetails?.price?.gross_fare.currency
      );
    },
  },
});
</script>
