<template>
  <div class="m-card">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MCard",
});
</script>

<style scoped>
.m-card {
  padding: 24px;
  border-radius: 8px;
  background: #fff;
}
</style>
