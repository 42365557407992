import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"font-weight":"bold","color":"#ed6c02","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_AgPnrDetail = _resolveComponent("AgPnrDetail")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_FlightDetailAccordion = _resolveComponent("FlightDetailAccordion")!
  const _component_PassengerDetailCard = _resolveComponent("PassengerDetailCard")!
  const _component_AdditionalDetailsCard = _resolveComponent("AdditionalDetailsCard")!
  const _component_AirlineTicketModal = _resolveComponent("AirlineTicketModal")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_AgSidebarThumb = _resolveComponent("AgSidebarThumb")!
  const _component_CancelBookingConfirmationDialog = _resolveComponent("CancelBookingConfirmationDialog")!
  const _component_PriceUpdatedDialog = _resolveComponent("PriceUpdatedDialog")!
  const _component_WaitListDialog = _resolveComponent("WaitListDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "8",
                lg: "9"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgRow, {
                        "test-id": "",
                        class: "v-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgColumn, {
                            "test-id": "",
                            md: "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgSelectedFlightInfo, null, {
                                pnrInfo: _withCtx(() => [
                                  _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Booking ID: "),
                                      _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_AgColumn, {
                            "test-id": "",
                            md: "3",
                            class: "text-right"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AGButton, {
                                "test-id": "",
                                type: "button",
                                variant: _ctx.renderStatusSeverity
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.renderBookingStatus), 1)
                                ]),
                                _: 1
                              }, 8, ["variant"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgPnrDetail, {
                        "test-id": "",
                        pnr: _ctx.supplierPnr,
                        expiry: _ctx.pnrExpiresAt
                      }, null, 8, ["pnr", "expiry"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ag_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "8",
                lg: "9"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        variant: "h2",
                        title: "Flight Details",
                        class: "margin_bottom_20"
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.flight_legs, (flightLeg, index) => {
                        return (_openBlock(), _createBlock(_component_FlightDetailAccordion, {
                          key: index,
                          "flight-leg": flightLeg,
                          index: index
                        }, null, 8, ["flight-leg", "index"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        variant: "h2",
                        title: "Passenger Details",
                        class: "margin_bottom_20"
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightDetails?.passengers, (passenger, index) => {
                        return (_openBlock(), _createBlock(_component_PassengerDetailCard, {
                          key: index,
                          passenger: passenger,
                          "is-international": _ctx.flightDetails?.is_international
                        }, null, 8, ["passenger", "is-international"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        variant: "h2",
                        title: "Additional Services",
                        class: "margin_bottom_20"
                      }),
                      _createVNode(_component_AdditionalDetailsCard, {
                        "has-meal": _ctx.hasMeal,
                        "luggage-info": _ctx.luggageInfo
                      }, null, 8, ["has-meal", "luggage-info"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.showAdditionalDetails]
                  ]),
                  _withDirectives(_createVNode(_component_AgCard, { "test-id": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_heading, {
                        variant: "h2",
                        title: "Additional Services Return Flight"
                      }),
                      _createVNode(_component_AdditionalDetailsCard, {
                        "has-meal": _ctx.isReturnMeal,
                        "luggage-info": _ctx.hasReturnluggageInfo
                      }, null, 8, ["has-meal", "luggage-info"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.showAdditionalDetailsOnReturn]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ag_column, {
                xs: "12",
                sm: "12",
                md: "4",
                lg: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgCard, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgSidebarThumb, {
                        "test-id": "",
                        thumb: "sidebarFlightThumb1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PriceSummary, null, {
                            flightDetailsActionButtons: _withCtx(() => [
                              (_ctx.isBookingCancelled)
                                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " Booking " + _toDisplayString(_ctx.renderBookingStatus), 1))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      disabled: _ctx.isFlightWaitListed,
                                      onClick: _ctx.onProceedToIssuance,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Proceed To Issuance")
                                      ]),
                                      _: 1
                                    }, 8, ["disabled", "onClick"]), [
                                      [_vShow, !_ctx.isFlightIssued]
                                    ]),
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isCancelBookingLoading,
                                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCancelationDialogOpen = true)),
                                      class: "full-width margin_bottom_10",
                                      "test-id": "",
                                      variant: "danger"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Cancel Booking")
                                      ]),
                                      _: 1
                                    }, 8, ["is-loading"]), [
                                      [_vShow, !_ctx.isFlightCancelled]
                                    ]),
                                    _withDirectives(_createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isVoidFlightTicketLoading,
                                      onClick: _ctx.onVoidPIATicket,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Void ticket")
                                      ]),
                                      _: 1
                                    }, 8, ["is-loading", "onClick"]), [
                                      [_vShow, _ctx.isIssuedAndisPIAAndisNotInternational]
                                    ]),
                                    _createVNode(_component_AGButton, {
                                      "is-loading": _ctx.isDownloadTicketLoading,
                                      onClick: _ctx.onDownloadTicket,
                                      class: "full-width margin_bottom_10",
                                      "test-id": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Download ticket")
                                      ]),
                                      _: 1
                                    }, 8, ["is-loading", "onClick"]),
                                    (_ctx.showDownloadAirlineTicketButton())
                                      ? (_openBlock(), _createBlock(_component_AGButton, {
                                          key: 0,
                                          "is-loading": _ctx.isDownloadTicketLoading,
                                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickAirlineTicket())),
                                          class: "full-width margin_bottom_10",
                                          "test-id": "emailTicket"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("Email ticket ")
                                          ]),
                                          _: 1
                                        }, 8, ["is-loading"]))
                                      : _createCommentVNode("", true)
                                  ], 64)),
                              _createVNode(_component_AirlineTicketModal, {
                                "show-modal": _ctx.showAirlineTicketModal,
                                onOnSendAirlineTicket: _ctx.onSendAirlineTicket,
                                onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAirlineTicketModal = false))
                              }, null, 8, ["show-modal", "onOnSendAirlineTicket"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 64)),
    _createVNode(_component_CancelBookingConfirmationDialog, {
      "is-open": _ctx.isCancelationDialogOpen,
      "handle-accpet": _ctx.onCancelBooking,
      "handle-reject": _ctx.handleCloseCancelationDialog
    }, null, 8, ["is-open", "handle-accpet", "handle-reject"]),
    _createVNode(_component_PriceUpdatedDialog, {
      "is-open": _ctx.isPriceUpdatedDialog,
      "handle-accpet": _ctx.handlePriceUpdateContinue,
      "gross-fare": _ctx.flightDetails?.equivalent_ag_total_amount || '-',
      currency: _ctx.Currency
    }, null, 8, ["is-open", "handle-accpet", "gross-fare", "currency"]),
    _createVNode(_component_WaitListDialog, { "is-open": _ctx.isWaitListDialog }, null, 8, ["is-open"])
  ], 64))
}