<template>
  <AgFlightDetailCard
    test-id=""
    :airline-logo="selectedLeg.airline.logo"
    :airline-code="selectedLeg.airline.code"
    :duration="renderLegDuration(selectedLeg.duration)"
    :from-destination="selectedLeg.origin.iata_code"
    :from-time="renderLegTime(selectedLeg.departure_time)"
    :to-time="renderLegTime(selectedLeg.arrival_time)"
    :to-destination="selectedLeg.destination.iata_code"
    featured="true"
    :date="renderDepartureDate(selectedLeg.departure_time)"
  >
    <template #button>
      <AGButton test-id="" variant="link" @click="handleChangeFlightClick"
        ><strong>Edit Flight</strong></AGButton
      >
    </template>
  </AgFlightDetailCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SelectedFlightLeg } from "../../types/Flights";
import { TimeUtility } from "../../utils/TimeUtility";
import { FORMAT_DD_MM_YYYY_WITH_SPACE } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "SelectedFlightLegItem",
  events: ["toggle"],
  props: {
    selectedLeg: {
      type: Object as () => SelectedFlightLeg,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    renderLegTime(time: Date) {
      const formatedTime = TimeUtility.parsedFlightTimeorDate(time);
      return formatedTime;
    },
    renderDepartureDate(date: Date) {
      return TimeUtility.parsedFlightTimeorDate(
        date,
        FORMAT_DD_MM_YYYY_WITH_SPACE
      );
    },
    renderLegDuration: (durationInNumber: number) => {
      const duration = TimeUtility.getDurationInTextByMinutes(durationInNumber);
      return duration;
    },
    handleChangeFlightClick() {
      this.$store.commit("removeSelectedFlightLeg", this.index);
    },
  },
});
</script>
