<template>
  <ag-card>
    <AgRow test-id="">
      <AgColumn test-id="" xs="12" md="6" cols="12">
        <AgSelectedFlightInfo
          :date="tripDate"
          :adult="$route.query.adult"
          :children="$route.query.child"
          :infant="$route.query.infant"
          :cabin-class="$route.query.cabin_class"
        >
          <template #destination>
            <FlightSearchResultLegInfo
              v-for="(leg, index) in $store.getters.allJourneyLegs"
              :key="index"
              :total-length="allJourneyLegsLength"
              :index="index"
              :leg="leg"
              :current-leg="$store.getters.currentLegIndex"
            />
          </template>
        </AgSelectedFlightInfo>
      </AgColumn>
      <AgColumn test-id="" xs="12" md="6" cols="12">
        <slot name="timer"></slot>
      </AgColumn>
    </AgRow>
  </ag-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import FlightSearchResultLegInfo from "./FlightSearchResultLegInfo.vue";
export default defineComponent({
  name: "SearchedFlightInfo",
  components: {
    FlightSearchResultLegInfo,
  },
  props: {
    tripDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    allJourneyLegsLength() {
      return this.$store.getters.allJourneyLegs.length;
    },
  },
  watch: {
    tripDate: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed********: ", newVal, " | was: ", oldVal);
    },
  },
  mounted() {
    console.log(
      "this.$store.getters.allJourneyLegs",
      this.$store.getters.allJourneyLegs
    );
  },
});
</script>
