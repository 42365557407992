<template>
  <div class="m-textarea" :disabled="disabled">
    <label :for="textareaId" class="m-label" :class="{ 'm-active': isActive }">
      {{ label }}
    </label>
    <textarea
      :id="textareaId"
      :value="inputValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput($event)"
      class="m-input"
      :placeholder="placeholder"
      :readonly="disabled"
    ></textarea>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MTextarea",
  data() {
    return {
      isActive: false,
      isFocused: false,
      textareaId: `m-textarea-${Math.random().toString(36).substring(7)}`,
    };
  },
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:inputValue", "change"],
  watch: {
    inputValue(val: string) {
      if (val === "") {
        if (!this.isFocused) {
          this.handleBlur();
        }
      }
    },
  },
  methods: {
    handleInput(event: Event): void {
      const inputElement = event.target as HTMLInputElement;
      this.$emit("update:inputValue", inputElement.value);
      this.$emit("change");
    },
    handleFocus() {
      this.isActive = true;
      this.isFocused = true;
    },
    handleBlur() {
      this.isActive = this.inputValue !== "" || this.placeholder !== "";
      this.isFocused = false;
    },
  },
});
</script>

<style scoped>
:root {
  --label-color: #333;
  --text-color: #555;
  --border-color: #ccc;
  --error-color: #ff0000;
}

.m-textarea {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.m-textarea[disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.m-label {
  position: absolute;
  top: 17px;
  left: 13px;
  padding: 0px 4px;
  pointer-events: none;
  transition: all 0.2s ease-out;
  color: var(--label-color);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  background: #fff;
}

.m-input {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  color: var(--text-color);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  transition: border-color 0.2s ease-out;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: #fff;
}

.m-input:focus {
  outline: none;
  border-color: var(--text-color);
}

.m-label.m-active {
  top: -7px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
