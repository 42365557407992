import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    persistent: "",
    "is-open": _ctx.isOpen,
    "modal-width": "40%"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_AgDiv, {
        "test-id": "",
        class: "text-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgIcon, {
            "test-id": "",
            name: "flightTimerThumb",
            class: "margin_bottom_20"
          }),
          _createVNode(_component_AgHeading, {
            title: "Session Expired",
            variant: "h3",
            "test-id": ""
          }),
          _createVNode(_component_AgHeading, {
            title: "Sorry, you ran out of time. Don’t worry you can book again",
            variant: "p"
          }),
          _createVNode(_component_AGButton, {
            "test-id": "",
            onClick: _ctx.handleRefreshResults,
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Search Again")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}