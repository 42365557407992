<template>
  <MulticityFlightItem
    v-for="(leg, index) in multiCityLegs"
    :errors="errors"
    :multicity-leg="leg"
    :index="index"
    :key="index"
  />
  <AgDiv v-if="showAddFlightBtn" test-id="" class="add_multiple_flight">
    <AGButton @click="addFlight" test-id="" variant="link"
      >+ Add Flight</AGButton
    ></AgDiv
  >
  <ag-row>
    <ag-column xs="12" sm="12" md="12">
      <AgDiv test-id="" class="text-center">
        <a-g-button
          @click="onFlightSearch"
          :isLoading="$store.getters.isFlightsLoading"
          >Search Flights</a-g-button
        >
      </AgDiv>
    </ag-column>
  </ag-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { addDays } from "date-fns";

import MulticityFlightItem from "./MulticityFlightItem.vue";
import { MulticityLeg } from "../../types/Flights";

export default defineComponent({
  name: "MulticityFlightSearch",
  components: {
    MulticityFlightItem,
  },
  props: {
    onFlightSearch: {
      type: Function,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  computed: {
    multiCityLegs() {
      return this.$store.state.flightModule.multiCityLegs;
    },
    showAddFlightBtn() {
      return this.$store.state.flightModule.multiCityLegs.length < 5;
    },
  },
  methods: {
    addFlight() {
      const multiCityLegs = this.$store.state.flightModule.multiCityLegs;
      const lastLeg = multiCityLegs[multiCityLegs.length - 1] as MulticityLeg;
      const payload = {
        origin: lastLeg.destination,
        destination: null,
        departure_date: new Date(addDays(lastLeg.departure_date, 1)),
      };
      this.$store.commit("addMulticityFlight", payload);
    },
    deleteFlight(index: number) {
      this.$store.commit("deleteMulticityFlight", index);
    },
  },
});
</script>
