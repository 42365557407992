<template>
  <AgAutoComplete
    v-model="searchValue"
    :value="selectedPassenger"
    type="text"
    :testId="
      genTestId(ELEMENT_TYPES.TEXT_FIELD, 'passenger-autocomplete-searchValue')
    "
    icon="calendar"
    :items="filteredPassengers"
    :onkeyup="onChangeSearchField"
    @update:modelValue="onUpdateModelValue"
    label="Passengers List"
    :is-loading="isPassengerFetching"
    class="agent-feild"
    :clearable="false"
  >
    <template #dropdownItem="{ itemProps, itemData }">
      <AgTextFieldDropDownItem
        v-bind="itemProps"
        :testId="
          genTestId(
            ELEMENT_TYPES.SELECT_FIELD,
            `passenger-autocomplete-${itemData.value}`
          )
        "
      >
        <p class="dropdown-item-p">{{ itemData.label }}</p>
      </AgTextFieldDropDownItem>
    </template>
  </AgAutoComplete>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { IPassenger } from "@/ag-portal-common/interfaces/passenger.interface";

export default defineComponent({
  name: "PassengerAutoComplete",
  props: {
    passengers: {
      type: Object as PropType<IPassenger[]>,
      default: () => [] as IPassenger[], // Use a function to return a new array instance
    },
    value: {
      type: Object as PropType<IPassenger | null>,
      required: true,
    },
  },
  data() {
    return {
      searchValue: "",
      filteredProfiles: [],
      ELEMENT_TYPES,
      selectedPassenger: null,
    };
  },
  methods: {
    genTestId,
    onChangeSearchField(e: Event) {
      const value = (e.target as HTMLInputElement).value;
      this.searchValue = value;
    },
    onUpdateModelValue(value: any) {
      this.searchValue = value?.fullName || "";
      this.$emit("onChange", value);
    },
  },
  computed: {
    filteredPassengers() {
      let filteredPassengers: any[] = this.passengers;
      if (this.searchValue) {
        filteredPassengers = this.passengers.filter((item: IPassenger) =>
          item.fullName?.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      }

      filteredPassengers = filteredPassengers
        .map((item: IPassenger) => ({
          label: `${item.fullName}`,
          value: item,
        }))
        .slice(0, 100);
      return filteredPassengers;
    },
    isPassengerFetching() {
      return this.$store.getters.isPassengerFetching;
    },
  },
  watch: {
    value: {
      handler: function (selectedPassenger) {
        if (selectedPassenger) {
          this.selectedPassenger = selectedPassenger;
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown-item-p {
  margin-bottom: 0;
  margin: 5px;
}
</style>
