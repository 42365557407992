import { RouteRecordRaw } from "vue-router";
import FlightRoutes from "@/modules/Flight/route";
import BookingRoutes from "@/modules/Booking/route";
import FlightIssuanceRoutes from "@/modules/FlightIssuance/route";

import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import { PATH } from "@/ag-portal-common/constants/path";

const HomeRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "flights",
    children: FlightRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS.PERMISSIONS,
    },
  },
  {
    path: "",
    name: "flightBookingForm",
    children: BookingRoutes,
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.FLIGHTS_BOOKING_FORM.PERMISSIONS,
    },
  },
  {
    path: PATH.FLIGHT_ISSUANCE,
    name: "flightIssuance",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FLIGHT_ISSUANCE.PERMISSIONS,
    },
    children: FlightIssuanceRoutes,
  },
];

export default HomeRoutes;
