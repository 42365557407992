import Mitt from "mitt";

export const popupAlertBus = Mitt();

export const POPUP_ALERT_EVENTS = {
  POPUP_ALERT: "POPUP_ALERT",
};

export const POPUP_ALERT_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};
