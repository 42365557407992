<template>
  <AgTimeRunOut
    test-id=""
    :time="formatedTime"
    text="Book now before tickets run out!"
  >
  </AgTimeRunOut>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlightResultsTimer",
  data() {
    return {
      timeRemaining: 600, // Seconds
      timerId: 0,
      timeOuted: false,
    };
  },
  computed: {
    formatedTime() {
      return this.formatDuration(this.timeRemaining);
    },
  },
  mounted() {
    this.timerId = setInterval(() => {
      if (this.timeRemaining > 0) {
        this.timeRemaining--;
      } else {
        this.timeOuted = true;
        clearInterval(this.timerId);
        this.$emit("timer:timeouted", this.timeOuted);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  methods: {
    formatDuration: (durationInSeconds: number) => {
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      return `${minutes < 10 ? "0" : ""}${minutes} : ${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
  },
});
</script>
