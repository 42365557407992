<template>
  <button :class="buttonClasses" :disabled="disabled">
    <span v-if="icon" class="m-icon">{{ icon }}</span>
    <span v-if="label" class="m-label">{{ label }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MButton",
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    type: {
      type: String,
      default: "filled",
      validator: (value: string) =>
        ["filled", "outlined", "text"].includes(value),
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    buttonClasses() {
      return {
        "m-button": true,
        "m-filled": this.type === "filled",
        "m-outlined": this.type === "outlined",
        "m-text": this.type === "text",
      };
    },
  },
});
</script>

<style scoped>
.m-button {
  height: 42px;
  width: fit-content;

  padding: 10px 24px;

  border-radius: 20px;

  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;

  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  outline: none;

  --theme-color: var(--green-color);
}

.m-button:hover {
  filter: brightness(95%);
}

.m-button:active {
  filter: brightness(90%);
}

.m-button:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.m-filled {
  background-color: var(--theme-color);
  color: #fff;
}

.m-outlined {
  border: 1px solid var(--theme-color);
  color: var(--theme-color);
}

.m-text {
  background: none;
  color: var(--theme-color);
}
</style>
