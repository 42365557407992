<template>
  <AgDiv>
    <span
      className="add_service"
      v-show="hasMeal"
      style="display: flex; align-items: center"
    >
      <img
        style="width: 35px; margin-right: 10px"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/breakfast.png?alt=media&amp;token=f8de8953-6d82-421f-8b73-af071d91e822"
      />
      <span className="title" style="font-weight: bold">Meal included</span>
    </span>
    <br />
    <span
      className="add_service"
      v-show="!!luggageInfo"
      style="display: flex; align-items: center"
    >
      <img
        style="width: 35px; margin-right: 10px"
        src="https://firebasestorage.googleapis.com/v0/b/aeroglobe-network.appspot.com/o/travel-luggage.png?alt=media&amp;token=5bd10394-1735-4d38-91b6-a1c0e1d62064"
      />
      <span className="title" style="font-weight: bold">
        Luggage included: {{ luggageInfo }}
      </span>
    </span>
  </AgDiv>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdditionalDetailsCard",
  props: {
    hasMeal: {
      type: Boolean,
      default: false,
    },
    luggageInfo: {
      type: String,
    },
  },
});
</script>
