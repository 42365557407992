<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.06688 23.8333L7.63354 22.4L14.0335 16L7.63354 9.6L9.06688 8.16667L16.9002 16L9.06688 23.8333ZM22.0002 24V8H24.0002V24H22.0002Z"
      fill="#49454F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MLastPageIcon",
});
</script>
