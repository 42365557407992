import RestClientService from "@/ag-portal-common/services/restClient.service";

import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import {
  formatString,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";

import {
  BOOKING_API_PATH,
  DOWNLOAD_FLIGHT_TICKET,
  CANCEL_BOOKINGS_API_PATH,
  VOID_PIA_TICKET,
  ORGANIZATION_PASSENGERSS,
  SEND_AIRLINE_TICKET,
} from "@/ag-flight-components/constants/apiEndPoints";
import {
  GetBookingPayloadType,
  ICancelBookingPayloadType,
  IConfirmBooking,
  IVoidBookingPayloadType,
  Traveler,
} from "@/ag-flight-components/types/FlightBookingForm";

export interface SendAirlineTicketBodyRequest {
  pnr: string;
  airline_provider: string;
  emails: string[];
}

class FlightBookingService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async sendAirlineTicket(payload: SendAirlineTicketBodyRequest) {
    const methodName = "MyBookingsService.sendAirlineTicket";
    try {
      const response: IAGResponse<any> = await this.restClient.post(
        SEND_AIRLINE_TICKET.POST,
        payload
      );

      if (response.success) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description =
          NOTIFICATION_MESSAGES.AIRLINE_TICKETS_SENT_SUCCESS;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.error || NOTIFICATION_MESSAGES.DEFAULT;
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    notificationService.triggerNotification();
  }
  public getBooking(params: GetBookingPayloadType): Promise<IAGResponse<any>> {
    const PATH = formatStringToRoutePath(BOOKING_API_PATH.GET, {
      id: params.booking_id,
    });
    return this.restClient.get(PATH);
  }
  public confirmBooking(params: IConfirmBooking): Promise<IAGResponse<any>> {
    return this.restClient.post(BOOKING_API_PATH.POST, params);
  }
  public cancelBooking(
    params: ICancelBookingPayloadType
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(CANCEL_BOOKINGS_API_PATH.POST, params);
  }
  public downloadFlightTicket(
    params: GetBookingPayloadType
  ): Promise<IAGResponse<any>> {
    const PATH = formatStringToRoutePath(DOWNLOAD_FLIGHT_TICKET.GET, {
      id: params.booking_id,
    });
    return this.restClient.get(
      PATH,
      {},
      {
        responseType: "blob",
      }
    );
  }
  public voidPIABooking(
    params: IVoidBookingPayloadType
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(VOID_PIA_TICKET.POST, params);
  }

  public getOrganizationPassengers(
    organizationId: string
  ): Promise<IAGResponse<any>> {
    return this.restClient.get(
      formatString(ORGANIZATION_PASSENGERSS.GET, organizationId)
    );
  }

  public createPassengers(
    body: Traveler[],
    organizationId: string
  ): Promise<IAGResponse<any>> {
    return this.restClient.post(
      formatString(ORGANIZATION_PASSENGERSS.POST, organizationId),
      body
    );
  }
}

export default FlightBookingService;
