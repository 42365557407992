// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export enum USER_ROLES {
  AGENT = 1,
  OPERATIONS = 2,
  FINANCE = 3,
  SALES = 4,
  MANAGEMENT = 5,
  SUPER_ADMIN = 6,
  AG_SUPER_USER = 7,
  SUB_AGENT = 1000001,
  EMPLOYEE = 2000001,
}

export function parseRoleAsUserRoles(role: number): USER_ROLES {
  return (
    (Object.values(USER_ROLES).find(
      (user_role) => user_role === role
    ) as USER_ROLES) || USER_ROLES.AGENT
  );
}

export function parseUserRoleAsUserRoleName(role: string): string {
  const userRole = parseRoleAsUserRoles(parseInt(role));
  switch (userRole) {
    case USER_ROLES.AGENT:
      return "OWNER";
    case USER_ROLES.OPERATIONS:
      return "OPERATIONS";
    case USER_ROLES.FINANCE:
      return "FINANCE";
    case USER_ROLES.SALES:
      return "SALES";
    case USER_ROLES.MANAGEMENT:
      return "MANAGEMENT";
    case USER_ROLES.SUPER_ADMIN:
      return "SUPER ADMIN";
    case USER_ROLES.AG_SUPER_USER:
      return "AG SUPER USER";
    case USER_ROLES.SUB_AGENT:
      return "SUB AGENT";
    case USER_ROLES.EMPLOYEE:
      return "EMPLOYEE";
  }
}
