<template>
  <div :class="headingClass">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type TypographyType = PropType<
  "display" | "headline" | "title" | "body" | "label"
>;

export default defineComponent({
  name: "MTypography",
  props: {
    type: {
      type: String as TypographyType,
      default: "body",
    },
  },
  computed: {
    headingClass() {
      return `m-typography-${this.type}`;
    },
  },
});
</script>

<style scoped>
.m-typography-display {
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 47.6px;
  color: var(--text-color);
  margin-bottom: 16px;
}

.m-typography-headline {
  font-family: Poppins;
  font-size: 24px;
  font-style: bold;
  font-weight: 600;
  color: var(--text-color);
}

.m-typography-title {
  font-family: Poppins;
  font-size: 20px;
  font-style: bold;
  font-weight: 600;
  color: var(--text-color);
}

.m-typography-body {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.2px;
  color: var(--text-color);
}

.m-typography-label {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--label-color);
}
</style>
