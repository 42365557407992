<template>
  <AgDiv class="multiple_flight">
    <AgDiv class="flight_count">Flight {{ index + 1 }}</AgDiv>
    <ag-row>
      <ag-column xs="12" sm="12" md="8">
        <AgDiv class="combine_flight">
          <ag-row test-id="">
            <ag-column xs="12" sm="12" md="6" cols="12">
              <AirportSearchAutoComplete
                @update:origin="searchOriginAirportQuery"
                @onSelect:origin="onSelectOrigin(index, $event)"
                label="From"
                name="origin"
                :items="renderOriginPlaces"
                :value="$store.state.flightModule.multiCityLegs[index].origin"
                :error="originError"
              />
            </ag-column>
            <AGButton
              class="compare_icon"
              variant="link"
              style="background-color: var(--green-color) !important"
            >
              <ag-icon name="compareIcon"></ag-icon>
            </AGButton>
            <ag-column xs="12" sm="12" md="6" cols="12">
              <AirportSearchAutoComplete
                @update:destination="searchDestinationAirportQuery"
                @onSelect:destination="onSelectDestination(index, $event)"
                label="To"
                name="destination"
                :items="renderDestinationPlaces"
                :value="
                  $store.state.flightModule.multiCityLegs[index].destination
                "
                :error="destinationError"
              />
            </ag-column>
          </ag-row>
        </AgDiv>
      </ag-column>

      <ag-column xs="12" sm="12" md="4">
        <ag-calendar
          label="Departure Date"
          :min-date="minDate"
          :date="multicityLeg!.departure_date"
          @update:departureDate="changeDepartureDate(index, $event)"
          calendar-name="departureDate"
          IconName="departureFlightIcon"
        />
      </ag-column>
    </ag-row>
    <AGButton
      variant="link"
      type="button"
      class="multiple_flight_close"
      v-show="index > 1"
      @click="deleteFlight(index)"
      ><svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
          fill="#111827"
        /></svg
    ></AGButton>
  </AgDiv>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { debounce } from "lodash";

import AirportSearchAutoComplete from "./AirportSearchAutoComplete.vue";
import { MulticityLeg } from "../../types/Flights";
import { Place } from "../../types/Place";

export default defineComponent({
  name: "MulticityFlightItem",
  components: {
    AirportSearchAutoComplete,
  },
  data() {
    return {
      departure_date: new Date(),
      originAirportQuery: "",
      destinationAirportQuery: "",
    };
  },
  props: {
    multicityLeg: { type: Object as PropType<MulticityLeg> },
    index: { type: Number, default: 0 },
    errors: {
      type: Object,
    },
  },
  computed: {
    originError() {
      return this.errors?.[`[${this.index}].origin`];
    },
    destinationError() {
      return this.errors?.[`[${this.index}].destination`];
    },
    renderOriginPlaces() {
      const airports = this.$store.getters.airports as Array<Place>;
      const selectedDestination = this.$store.state.flightModule.multiCityLegs[
        this.index
      ]?.destination as Place;
      if (selectedDestination) {
        return airports
          .filter((x) => x.iata_code !== selectedDestination.iata_code)
          .filter((x) => {
            if (selectedDestination?.country === "Pakistan") {
              return x?.country !== "Pakistan";
            }
            return true;
          });
      }
      return airports;
    },
    renderDestinationPlaces() {
      const airports = this.$store.getters.airports as Array<Place>;
      const selectedOrigin = this.$store.state.flightModule.multiCityLegs[
        this.index
      ]?.origin as Place;
      if (selectedOrigin) {
        return airports
          .filter((x) => x.iata_code !== selectedOrigin.iata_code)
          .filter((x) => {
            if (selectedOrigin?.country === "Pakistan") {
              return x?.country !== "Pakistan";
            }
            return true;
          });
      }
      return airports;
    },
    minDate() {
      const allLegs = this.$store.state.flightModule.multiCityLegs;
      return this.index > 0
        ? allLegs[this.index - 1].departure_date
        : new Date();
    },
  },
  methods: {
    changeDepartureDate(index: number, date: Date) {
      this.$store.commit("updateMulticityDepartureDate", {
        index,
        date,
      });
    },
    onSelectOrigin(index: number, selectedAirport: Place) {
      this.$store.commit("updateMulticityOrigin", {
        index,
        origin: selectedAirport,
      });
    },
    onSelectDestination(index: number, selectedAirport: Place) {
      this.$store.commit("updateMulticityDestination", {
        index,
        destination: selectedAirport,
      });
    },
    searchOriginAirportQuery(value: string) {
      this.originAirportQuery = value;
    },
    searchDestinationAirportQuery(value: string) {
      this.destinationAirportQuery = value;
    },
    searchAirports(value: string) {
      if (value && value.length > 2) {
        this.$store.dispatch("fetchAirports", value);
      }
    },
    deleteFlight(index: number) {
      this.$store.commit("deleteMulticityFlight", index);
    },
  },
  watch: {
    originAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchAirports(value);
      }, 500),
      immediate: true,
    },
    destinationAirportQuery: {
      handler: debounce(function (value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.searchAirports(value);
      }, 500),
      immediate: true,
    },
  },
});
</script>
