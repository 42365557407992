import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_AgAlertBox = _resolveComponent("AgAlertBox")!
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    persistent: "",
    "is-open": _ctx.isOpen,
    class: "change_search_modal",
    "modal-width": "30%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _ctx.handleClose
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_AgDiv, {
        "test-id": "",
        class: "text-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AgAlertBox, {
            "test-id": "",
            "alert-type": _ctx.alertType,
            class: "modal_alert"
          }, null, 8, ["alert-type"]),
          _createVNode(_component_AgIcon, {
            "test-id": "",
            name: "",
            class: "margin_bottom_20"
          }),
          _createVNode(_component_AgHeading, {
            title: _ctx.title,
            variant: "h2",
            "test-id": ""
          }, null, 8, ["title"]),
          _createVNode(_component_AgHeading, {
            title: _ctx.description,
            variant: "p",
            "test-id": ""
          }, null, 8, ["title"]),
          _createVNode(_component_AgHeading, {
            title: _ctx.renderDate,
            variant: "h3",
            "test-id": ""
          }, null, 8, ["title"]),
          _createVNode(_component_AGButton, {
            "test-id": "",
            onClick: _ctx.handleClose,
            variant: "primary",
            type: "button"
          }, {
            default: _withCtx(() => [
              _createTextVNode("OK")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}