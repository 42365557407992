import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgPricingDropdown = _resolveComponent("AgPricingDropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showStatus)
      ? (_openBlock(), _createBlock(_component_AGButton, {
          key: 0,
          disabled: _ctx.isLoadingFinances,
          "test-id": "",
          variant: "primary",
          class: "fire_btn_wrap",
          icon: "fireIcon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickFinancialStatusTrack()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isAccountActive ? _ctx.accountStatus : _ctx.statusRemarks), 1)
          ]),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true),
    (_ctx.showStatus)
      ? (_openBlock(), _createBlock(_component_AGButton, {
          key: 1,
          "is-loading": _ctx.isLoadingFinances,
          "test-id": "",
          variant: "primary",
          class: "fire_btn_wrap",
          icon: "refreshIcon",
          onClick: _ctx.handleRefreshBalance
        }, {
          default: _withCtx(() => [
            _createTextVNode("Refresh balance ")
          ]),
          _: 1
        }, 8, ["is-loading", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AgPricingDropdown, {
      "is-loading": _ctx.isLoadingFinances,
      "current-limit": _ctx.formattedAmount(_ctx.agentFinances.currentLimit),
      "current-balance": _ctx.formattedAmount(_ctx.agentFinances.currentBalance),
      "permanent-credit-limit": 
      _ctx.formattedAmount(_ctx.agentFinances.permanentCreditLimit)
    ,
      "total-not-invoiced-ticket": 
      _ctx.formattedAmount(_ctx.agentFinances.totalNotInvoicedTicket)
    ,
      "available-credit": _ctx.formattedAmount(_ctx.agentFinances.availableCredit),
      "temporary-credit-limit": 
      _ctx.formattedAmount(_ctx.agentFinances.temporaryCreditLimit)
    ,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickFinancialBreakdownTrack())),
      "test-id": ""
    }, null, 8, ["is-loading", "current-limit", "current-balance", "permanent-credit-limit", "total-not-invoiced-ticket", "available-credit", "temporary-credit-limit"])
  ], 64))
}