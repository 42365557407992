<template>
  <AGHeader class="dontAddThis">
    <AGButton
      v-a-g-toggle:class.on-id.main_container="'menu_collapse'"
      variant="link"
      type="button"
      data-toggle-class="active"
      style="background-color: var(--green-color) !important"
      class="menu_toggle_icon"
      @click="onClickSidebarToggleTrack()"
    >
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.599609 1.9998C0.599609 1.68154 0.726038 1.37632 0.951081 1.15128C1.17612 0.926233 1.48135 0.799805 1.79961 0.799805H16.1996C16.5179 0.799805 16.8231 0.926233 17.0481 1.15128C17.2732 1.37632 17.3996 1.68154 17.3996 1.9998C17.3996 2.31806 17.2732 2.62329 17.0481 2.84833C16.8231 3.07338 16.5179 3.1998 16.1996 3.1998H1.79961C1.48135 3.1998 1.17612 3.07338 0.951081 2.84833C0.726038 2.62329 0.599609 2.31806 0.599609 1.9998ZM0.599609 7.9998C0.599609 7.68155 0.726038 7.37632 0.951081 7.15128C1.17612 6.92623 1.48135 6.7998 1.79961 6.7998H16.1996C16.5179 6.7998 16.8231 6.92623 17.0481 7.15128C17.2732 7.37632 17.3996 7.68155 17.3996 7.9998C17.3996 8.31806 17.2732 8.62329 17.0481 8.84833C16.8231 9.07338 16.5179 9.19981 16.1996 9.19981H1.79961C1.48135 9.19981 1.17612 9.07338 0.951081 8.84833C0.726038 8.62329 0.599609 8.31806 0.599609 7.9998ZM0.599609 13.9998C0.599609 13.6815 0.726038 13.3763 0.951081 13.1513C1.17612 12.9262 1.48135 12.7998 1.79961 12.7998H8.99961C9.31787 12.7998 9.62309 12.9262 9.84814 13.1513C10.0732 13.3763 10.1996 13.6815 10.1996 13.9998C10.1996 14.3181 10.0732 14.6233 9.84814 14.8483C9.62309 15.0734 9.31787 15.1998 8.99961 15.1998H1.79961C1.48135 15.1998 1.17612 15.0734 0.951081 14.8483C0.726038 14.6233 0.599609 14.3181 0.599609 13.9998Z"
          fill="#10B981"
        />
      </svg>
    </AGButton>
    <ag-heading class="nav_heading" :title="title" variant="h4"></ag-heading>

    <AgDiv class="d-flex align-items-center header_element_align">
      <AgentFinancesWidget
        v-if="userData?.role === USER_ROLES.AGENT"
        showStatus
      />
      <AGButtonDropDown text="Test" :items="ddOptions" class="profile_dropdown">
        <template #label
          ><i>{{ displayName }}</i> <span>{{ displayName[0] }}</span></template
        >
        <AGDropDownButtonItem
          v-for="(item, index) in ddOptions"
          :key="index"
          :icon="item.icon"
          :name="item.name"
          @click="
            item.onClick();
            onClickDDOptionsTrack(item.name);
          "
        />
      </AGButtonDropDown>
    </AgDiv>
    <template #mobilePricing>
      <AgentFinancesWidget v-if="userData?.role === USER_ROLES.AGENT" />
    </template>
  </AGHeader>
</template>

<script>
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import { inject } from "vue";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import AgentFinancesWidget from "@/ag-portal-common/widgets/AgentFinancesWidget/view/index.vue";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import analyticsService from "@/ag-portal-common/services/local_analytics.service";
import { ANALYTICS_COMMON_EVENTS } from "@/ag-portal-common/constants/analytics";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  components: { AgentFinancesWidget },
  data() {
    return {
      sidebarState: true,
      email: "",
    };
  },
  props: ["title"],
  computed: {
    USER_ROLES() {
      return USER_ROLES;
    },
    userData() {
      return this.user();
    },
    displayName() {
      const organization = this.organizationPreference();
      if (organization) {
        if (organization?.display_name) return organization?.display_name;
        if (organization?.org_name_to_display)
          return organization?.org_name_to_display;
      }
      return this.user()?.full_name || "-";
    },
    ddOptions() {
      return [
        {
          name: "Logout",
          onClick: () => authBus.emit(AUTH_EVENTS.LOGOUT),
          icon: "logout",
        },
      ];
    },
  },
  methods: {
    onClickSidebarToggleTrack() {
      const payload = {
        status: this.sidebarState,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.HEADER_ITEM_SIDEBAR_TOGGLE,
        payload
      );

      this.sidebarState = !this.sidebarState;
    },
    onClickDDOptionsTrack(name) {
      const payload = {
        "item-name": name,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.HEADER_ITEM_DROPDOWN,
        payload
      );
    },
  },
  setup() {
    const user = inject(AUTH_CONTEXT_KEYS.user);
    const organizationPreference = inject(
      AUTH_CONTEXT_KEYS.organizationPreference
    );

    return {
      user,
      organizationPreference,
    };
  },
};
</script>

<style scoped>
@media print {
  .dontAddThis {
    display: none;
  }
}
</style>
